import { TimePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { padStart } from 'lodash'
import React, { Component} from 'react'
import './styles.css'
import { timeZones } from './time-zones'
import * as Constants from './constants'

interface TimePickerState {
  showTimeZone?: boolean,
  time: string,
  timeZone: string,
}
interface TimePickerProps extends TimePickerState {
  disabled?: boolean,
  limitPast?: boolean,
  min?: Date,
  onChange: any,
  format?: any,
}

export class RasaTimePicker extends Component<TimePickerProps, TimePickerState> {
  constructor(props: TimePickerProps) {
    super(props)
    this.state = {
      showTimeZone: props.showTimeZone ? props.showTimeZone : false,
      time: props.time,
      timeZone: props.timeZone,
    }
  }

  public componentDidUpdate(prevProps: TimePickerProps, prevState: TimePickerProps, snapshot: TimePickerProps) {
    if ((this.props.time !== prevProps.time) || (this.props.timeZone !== prevProps.timeZone)) {
      this.setStateFromProps(false, this.props)
    }
  }

  public render() {
    return <div className="time-picker-wrapper">
      <div className="time-picker-box">
        <div>
          <TimePicker /*format="hh:mm a"*/
            disabled={this.props.disabled}
            steps={{
              hour: 1,
              minute: 15,
            }}
            format = {this.props.format || Constants.RASA_TIME_PICKER_FORMAT}
            onChange = {this.timeChange}
            value = {this._displayTime()}
            min={this._getMininumTime()}
          />
        </div>
      </div>
      {this.state.showTimeZone && (
      <div className="time-picker-box">
        <div>
          <DropDownList
            data={timeZones.usCommon.zones}
            value={this._displayTimeZone()}
            textField="name"
            dataItemKey="value"
            onChange = {this.timeZoneChange}
          />
        </div>
      </div>)}
    </div>
  }

  private timeChange = (e: any) => {
    if (e.value) {
      const timeOnly = `${padStart(e.value.getHours(), 2, '0')}:${padStart(e.value.getMinutes(), 2, '0')}`
      this._handleStateChange(timeOnly, this.state.timeZone)
    }
  }

  private timeZoneChange = (e: any) => {
    this._handleStateChange(this.state.time, e.target.value.value)
  }

  private _handleStateChange = (time: string, timeZone: string) => {
    const newState: TimePickerState = {
      ...this.state,
      time,
      timeZone,
    }

    this.setState(newState)
    if (this.props.onChange) {
      this.props.onChange(newState)
    }
  }

  private _displayTimeZone(): any {
    if (this.state.timeZone) {
      return timeZones.usCommon.zones.filter((e: any) => e.value === this.state.timeZone)[0]
    } else {
      // get the time zone from where the user is located via JavaScript getTimezoneOffset() below...
      const offset = new Date().getTimezoneOffset();
      return timeZones.usCommon.zones.filter((e: any) => e.offset === offset)[0]
    }
  }

  private _displayTime(): Date {
    const theTime = new Date()
    if (this.state.time) {
      // parse the string to get hour and minute
      const iPos = this.state.time.indexOf(':')
      const hour = this.state.time.substring(0, iPos)
      const minute = this.state.time.substring(iPos + 1, this.state.time.length)
      theTime.setHours(+hour)
      theTime.setMinutes(+minute)
    }
    return theTime
  }

  private _getMininumTime(): Date {
    if (this.props.limitPast && this.state.time) {
      const minimumTime = new Date()
      // parse the string to get hour and minute
      const iPos = this.state.time.indexOf(':')
      const hour = this.state.time.substring(0, iPos)
      const minute = this.state.time.substring(iPos + 1, this.state.time.length)
      minimumTime.setHours(+hour)
      minimumTime.setMinutes(+minute)
      return minimumTime
    } else if (this.props.min) {
      return this.props.min
    } else {
      return undefined
    }
  }

  private setStateFromProps(bDirect: boolean, props: TimePickerProps) {
    const newState = {...props}
    if (bDirect) {
      this.state = newState
    } else {
      this.setState(newState)
    }
  }
}
