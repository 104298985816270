import { Loading } from 'components/loading';
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset';
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent';
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore';
import { AnalyticsWeeklyAverageComponent } from './weeklyAverage';

interface StatisticsComponentProps extends GenericRedux.AllComponentProps<any> {
  hideScheduleWidget?: boolean,
  hideWeeklyAvgWidget?: boolean,
}
interface StatisticsComponentState {
  communityId: string,
  isLoading: boolean,
  nextIssue: any,
  lastIssue: any,
  schedule: any,
}
export class StatisticsComponent extends RasaReactComponent<StatisticsComponentProps, StatisticsComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: StatisticsComponentProps) {
    super(props, 'communitySubscriberCounts', {
      communityId: '',
      isLoading: true,
      nextIssue: {},
      lastIssue: {},
      schedule: {},
    })
    this.loadLastIssue = this.loadLastIssue.bind(this)
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityId: activeCommunity.communityId,
        nextIssue: activeCommunity.nextIssue,
        schedule: activeCommunity._communityInfo.data.schedule[0],
      })
      if (!this.props.hideScheduleWidget) {
        this.loadRecord(activeCommunity.communityId, activeCommunity.communityId).then(() => {
          this.setState({
            nextIssue: {
              ...this.state.nextIssue,
              num_sent: this.props.data.active,
            },
          }, () => this.loadLastIssue())
        })
      } else {
        this.setState({
          isLoading: false,
        })
      }
    })
  }
  public render() {
      return (
        <div className="statistics">
          {this.state.isLoading ?
              <div>
                <Loading size="64"/>
              </div>
              :
          <div className="statistics-widget">
            {!this.props.hideWeeklyAvgWidget &&
            <div className="weekly-avg-widget">
              <AnalyticsWeeklyAverageComponent lastIssue={this.state.lastIssue} nextIssue={this.state.nextIssue}  />
            </div>}
            <div className="clearfix"></div>
          </div>}
        </div>
      );
  }
  private loadLastIssue = () => {
    return new Dataset().loadCommunityDataset('communityIssues', this.state.communityId, null)
      .then((communityIssues) => {
        if (communityIssues[0]) {
          this.setState({
            isLoading: false,
            lastIssue: {
              ...communityIssues[0][0],
              schedule: this.state.schedule,
              date: communityIssues[0][0].send_at,
            },
          })
        }
        return {}
      })
  }
}
export const Statistics = GenericRedux.registerNewNestedComponent(
  StatisticsComponent, 'edit_source')
