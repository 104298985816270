import { HeaderLogo } from 'components/icons/menu-header-logo'
import { SettingsIcon} from 'components/icons/settings'
import React from 'react'
import * as Types from '../types'
import * as Utils from '../utils'

export enum UnsubscribeLayoutAttributes {
  ShowHeader =  'unsubscribe_header_show',
  HeaderImageUrl = 'unsubscribe_header_image_url',
  HeaderText = 'unsubscribe_header_text',
  HeaderDescription = 'unsubscribe_header_description',
  UnsubscribeButtonText = 'unsubscribe_button_text',
  UnsubscribeButtonColor = 'unsubscribe_button_color',
  ResubscribeHeaderText = 'resubscribe_header_text',
  ResubscribeHeaderTextHtmlEdited= 'resubscribe_header_text_html_edited',
  ResubscribeHeaderDescription = 'resubscribe_header_description',
  ResubscribeButtonText = 'resubscribe_button_text',
  ResubscribeButtonColor = 'resubscribe_button_color',
  BrandHeaderImageUrl = 'brand_header_image_url',
}

export const REDUX_STORE_HOME = 'signup_layout'

export enum MenuItems {
  headerAndLogo = 'Header Logo',
  cta = 'CTA',
  confirmation = 'Confirmation',
}

export const MenuSections: Types.MenuSection[] = [
  {
    icon: <HeaderLogo />,
    // key: 'headerAndLogo',
    key: Utils.getEnumHash(MenuItems.headerAndLogo, MenuItems),
    value: MenuItems.headerAndLogo,
  },
  {
    icon: <SettingsIcon svgwidth={27.5} svgheight={27.5} />,
    // key: 'signup',
    key: Utils.getEnumHash(MenuItems.cta, MenuItems),
    value: MenuItems.cta,
  },
  ,
  {
    icon: <SettingsIcon svgwidth={27.5} svgheight={27.5} />,
    // key: 'signup',
    key: Utils.getEnumHash(MenuItems.confirmation, MenuItems),
    value: MenuItems.confirmation,
  },
]
