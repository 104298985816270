import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import * as Redux from 'redux'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import {injectReducer} from 'store/index'
import { HeaderSettings } from '../headerSettings'
import { PreviewWidgetMenuComponent } from '../previewMenuWidget'
import '../styles.css'
import { MenuSection } from '../types'
import {
  MenuItems,
  MenuSections,
  REDUX_STORE_HOME,
  UnsubscribeLayoutAttributes,
} from './constants'
import { ResubscribeSettings } from './layouts/resubscribeSettings'
import { UnsubscribeSettings } from './layouts/unsubscribeSettings'
import { PreviewComponent } from './preview'
import './style.scss'

type WidgetsUnsubscribeComponentProps = GenericRedux.AllComponentProps<any>
interface WidgetsUnsubscribeComponentState {
  communityId: string,
  communityRecordId: string,
  communityGuid: string,
  configurations: any,
  context?: any,
  selected: MenuSection,
}

class WidgetsUnsubscribeComponent extends RasaReactComponent<WidgetsUnsubscribeComponentProps,
      WidgetsUnsubscribeComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: WidgetsUnsubscribeComponentProps) {
    super(props, 'widgetsLayout', {
      communityId: '',
      communityRecordId: '',
      communityGuid: '',
      selected: null,
      configurations: null,
    })
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.hash),
    ])
    .then(([activeCommunity, hash]) => {
      this.loadRecord(activeCommunity.communityId, activeCommunity.data.community_id)
      .then(() => {
        this.setState({
          communityId: activeCommunity.communityId,
          communityRecordId: activeCommunity.data.community_id,
          communityGuid: activeCommunity.data.community_guid,
          configurations: this.props.data,
        })
      })
    })
  }

  public render() {
    return (
      <div className="unsubscribe-design">
      {this.state.isLoading ? <Loading size="64" /> :
      <div className="unsubscribe-container">
        <div className="preview">
          <div className="unsubscribe-body"
               style={{backgroundColor: '#FFFFFF', margin: '0px'}}>
              <PreviewComponent configurations={this.state.configurations} data={this.props.data} select={this.state.selected} />
          </div>
        </div>
        <div className="settings">
          <div className="designbuttonwrap">
            <div className={this.buttonClassNames(this.isDirty)}
                  onClick={() => this.saveRecord(this.state.communityRecordId)}>
              Save
            </div>
          </div>
          <div className="editor">
            {this.getEditor()}
          </div>
        </div>
        <div className="menu">
          <PreviewWidgetMenuComponent
            selectItem={this.selectItem}
            saveRecord={this.saveRecord}
            isDirty={this.isDirty} menuSections={MenuSections}>
          </PreviewWidgetMenuComponent>
        </div>
      </div>
      }
    </div>
    )
  }
  private buttonClassNames = (enabled: boolean): string => {
    return enabled ? 'save-button save-button-enabled clickable-item' :
                     'save-button save-button-disabled'
  }
  private selectItem = (item: MenuSection) => {
    this.setState({
      selected: item,
    })
  }
  private getEditor = () => {
    if (this.state.selected) {
      switch (this.state.selected.value) {
        case MenuItems.headerAndLogo:
          return this.getHeaderSettings()
        case MenuItems.confirmation:
          return <ResubscribeSettings data={this.props.data}
                    name={MenuItems.confirmation}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
        case MenuItems.cta:
          return <UnsubscribeSettings data={this.props.data}
                    name={MenuItems.cta}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
      }
    } else {
      return this.getHeaderSettings()
    }
    return <div>No Editor Found</div>
  }

  private getHeaderSettings = () => {
    return <HeaderSettings data={this.props.data}
    headerTextAttribute={UnsubscribeLayoutAttributes.HeaderText}
    imageUrlAttribute={UnsubscribeLayoutAttributes.HeaderImageUrl}
    showHeaderAttribute={UnsubscribeLayoutAttributes.ShowHeader}
    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
  }
}

export const WidgetsUnsubscribe = GenericRedux.createConnect(WidgetsUnsubscribeComponent, REDUX_STORE_HOME)
injectReducer(
  REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(REDUX_STORE_HOME, {}),
  }),
)
