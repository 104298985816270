import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import { getConfiguration } from '../../utils'
import { SignupLayoutAttributes } from '../constants'

export const HeaderComponent = ({data}) =>
  <ReactStrap.Navbar className="rasa-public-header">
    <ReactStrap.Nav className="center">
      <ReactStrap.NavItem className="center">
        {getConfiguration(data, SignupLayoutAttributes.ShowHeader) ?
          getConfiguration(data, SignupLayoutAttributes.HeaderImageUrl) ?
          <img className="brand-header" src={
            getConfiguration(data, SignupLayoutAttributes.HeaderImageUrl)}/> :
            getConfiguration(data, SignupLayoutAttributes.BrandHeaderImageUrl) ?
          <img className="brand-header" src={
            getConfiguration(data, SignupLayoutAttributes.BrandHeaderImageUrl)}/> : null : null}

      </ReactStrap.NavItem>
    </ReactStrap.Nav>
  </ReactStrap.Navbar>
