import * as React from 'react'
import { Button, Col, Container, Row} from 'reactstrap'
import { getConfiguration } from '../../utils'
import { UnsubscribeLayoutAttributes } from '../constants'
import { HeaderComponent } from './headerComponent'

export const Unsubscribe = ({data}) =>
  <div>
    <HeaderComponent data={data} />
    <Container>
      <Row>
        <Col>
            <header className="unsub-page-header text-center"
              dangerouslySetInnerHTML={{
                __html: getConfiguration(data, UnsubscribeLayoutAttributes.HeaderText),
              }}>
            </header>
        </Col>
      </Row>
        <div>
          <Row className="unsub-page-header schedule-row">
            <Col>
              <div className="text-center"
                dangerouslySetInnerHTML={{
                  __html: getConfiguration(data, UnsubscribeLayoutAttributes.HeaderDescription),
                }}>
              </div>
            </Col>
          </Row>
          <Row className="schedule-row">
            <Col className="text-center">
              <Button disabled={true}
                style={{backgroundColor: getConfiguration(data,
                UnsubscribeLayoutAttributes.UnsubscribeButtonColor, '')}}>
                {getConfiguration(data, UnsubscribeLayoutAttributes.UnsubscribeButtonText)}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
