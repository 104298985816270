import { OpenLinkIcon } from 'components/icons/openlink'
import { EmailSent } from 'components/icons/emailSent'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import React, {Component} from 'react'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import * as GenericRedux from '../../generic/genericRedux'
import { copyToClipboard } from '../../generic/utility'
import './styles.css'
import { Dataset } from 'generic/dataset'
import { getDummyViewInBrowserLink, getViewInBrowserUrl } from './utils'
import { Loading } from 'components/loading'

const MAX_URLS_TO_SHOW = 5

interface ViewInBrowserLinkComponentProps {
  communityId: string,
  name: string,
  description: string,
  issues: any[],
  link: string,
}

const ViewInBrowserLinkComponent = (props: ViewInBrowserLinkComponentProps) =>
  <div className="view-in-browser-link">
    <div className='link-wrapper'>
      <div className="left-part">
        <div className="first-line">
          <div className="name">{props.name}</div>
        </div>
        <div className="description">{props.description}</div>
      </div>
      <div className="middle-part">
        <a target="_blank" href={props.link} className="open-link"><OpenLinkIcon /></a>
      </div>
      <div className="right-part">
        <input type="text" value={props.link} readOnly={true}/>
        <button onClick={() => copyToClipboard(props.link) }>Copy</button>
      </div>
    </div>
    <div className="footer">
      <div className="url-title">
        Here are some example URLs from your recent newsletters:
      </div>
      <div className="url-wrapper">
        {renderExampleURLs(props.issues, props.communityId)}
      </div>
    </div>
  </div>

const renderExampleURLs = (issues: any[], communityId: string) => {
  // Group issues by send_at date
  const groupedIssues = new Map<string, any[]>();
  issues.forEach(issue => {
    const dateKey = format(new Date(issue.send_at), DEFAULT_DATE_FORMAT)
    if (!groupedIssues.has(dateKey)) {
        groupedIssues.set(dateKey, []);
    }
    groupedIssues.get(dateKey)!.push(issue);
  });
  const urls = []
  groupedIssues.forEach((group, date) => {
    urls.push(getViewInBrowserUrl(communityId, date))
  });
  return urls.slice(0, MAX_URLS_TO_SHOW).map((url) => {
    return <div className="example-urls-wrapper">
        <div className="view-in-browser-example-urls">{url}</div>
        <button onClick={() => copyToClipboard(url) }>Copy</button>
      </div>
  })
}

interface WidgetsViewInBrowserState {
  communityId: string,
  communityGuid: string,
  isLoading: boolean,
  issues: any[],
  lastIssue: any,
}

class WidgetsViewInBrowserComponent extends Component<any, WidgetsViewInBrowserState> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)

    this.state = {
      communityId: '',
      communityGuid: '',
      isLoading: true,
      issues: [],
      lastIssue: {},
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        communityGuid: activeCommunity.data.community_guid,
      }, () => {
        this.loadLastIssue()
      })
    })
  }

  private loadLastIssue = () => {
    return new Dataset().loadCommunityDataset('communityIssues', this.state.communityId, null)
      .then((communityIssues) => {
        if (communityIssues[0]) {
          this.setState({
            isLoading: false,
            issues: communityIssues[0],
            lastIssue: {
              ...communityIssues[0][0],
            },
          })
        }
        return {}
      })
  }

  public render() {
    return (
      this.state.isLoading ?
        <Loading size="64" /> : this.state.issues.length > 0 ?
      <div className="widgets-tools-view-in-browser">
        <div className="contentpane-header-wrapper">
          <div className="contentpane-icon-wrapper">
            <EmailSent/>
          </div>
          <div className="header">
            <div className="contentpane-section-title-wrapper">
              <div className="section-header-text">View In Browser</div>
            </div>
          </div>
        </div>
        <ViewInBrowserLinkComponent
          communityId={this.state.communityId}
          name="View In Browser"
          description="This URL will display the newsletter from the date provided at the end of this URL"
          issues={this.state.issues}
          link={getDummyViewInBrowserLink(this.state.communityId)}/>
     </div> : null
    )
  }
}

export const WidgetsViewInBrowser = GenericRedux.registerNewDatasetComponent(
  WidgetsViewInBrowserComponent, 'widgets_view_in_browser')
