import { SelectColor } from 'components/colors/component'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { getConfiguration } from '../../utils'
import { TopArticlesLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'

export class SignupSettings extends React.Component<EditSectionProps> {
  constructor(props: EditSectionProps) {
    super(props)
  }

  public render() {
    const isShowSignup: boolean =
      isTruthy(getConfiguration(this.props.data, TopArticlesLayoutAttributes.ShowSignup))
    return <div className="logo-size">
      <span className="title">{this.props.name}</span>
      <div className="section">
        <div className="block">
          <div className="toggle-active"
               onClick={() => {
                 this.props.onChange(TopArticlesLayoutAttributes.ShowSignup, !isShowSignup)
               }}>
            <button className="toggle-button"
                    style={{background: 'none', border: 'none', outline: 'none'}}>
              {isShowSignup ? <ToggleOn/> : <ToggleOff/> }
            </button>
            <span className="small-description">Show/Hide</span>
          </div>
        </div>
      </div>
      <br />
      <div className="section">
        <div className="small-title">
          Signup Text
        </div>
        <WysiwygEditor
          htmlEdited={false}
          html={this.getSignupText()}
          onChange={(html: string, isHtmlEdited: boolean) => {
            this.props.onChange(TopArticlesLayoutAttributes.SignupText, html)
          }}/>
      </div>
      <div className="section">
        <div className="block">
          <div className="title">Signup Button</div>
          <div>
            <span>Text</span>
            <Input value={
              getConfiguration(this.props.data, TopArticlesLayoutAttributes.SignupButtonText)}
                   maxLength={100}
                   onChange={(e) => {
                     this.props.onChange(TopArticlesLayoutAttributes.SignupButtonText, e.target.value)
                   }}/>
          </div>
          <div>
            <span>Color</span>
            <SelectColor
              key={TopArticlesLayoutAttributes.SignupButtonColor}
              className={'secondary'}
              selectColor={(c: string) => {
                this.props.onChange(TopArticlesLayoutAttributes.SignupButtonColor, c)}}
              color={getConfiguration(this.props.data,
                TopArticlesLayoutAttributes.SignupButtonColor, '#000000')} />
          </div>
        </div>
      </div>
    </div>
  }
  private getSignupText = (): any => {
    const signupText = getConfiguration(this.props.data, TopArticlesLayoutAttributes.SignupText)
    return signupText ? signupText : ''
  }
}
