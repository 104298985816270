import { Upgrade } from 'components/icons/upgrade'
import React from 'react'
import './styles.scss'
import { SUPPORT_MAIL_TO, SUPPORT_EMAIL } from '../../constants';

export interface ContactToSupportComponentProps extends React.HTMLAttributes<HTMLElement> {
  headline?: string,
  description?: string,
  onCancel?: any,
}

export class ContactToSupportComponent extends React.Component<ContactToSupportComponentProps> {
  constructor(props: ContactToSupportComponentProps) {
    super(props)
  }
  public render() {
    return (
      <div className={`upgrade-box ${this.props.onCancel ? 'customTemplate' : ''}`}>
        <div className="logo">
          <Upgrade svgwidth={30} svgheight={30}/>
        </div>
        <div className="title">
          {this.props.headline}
        </div>
        <div className="description text-center">
          {
            this.props.description ? this.props.description : <>
              Please contact <a href={SUPPORT_MAIL_TO}>{SUPPORT_EMAIL}</a> <br/>
              to to discuss your design and template options.
            </>
          }
        </div>
        <div className='close-icon'>
          {
            this.props.onCancel ?
              <i className="fa fa-window-close" onClick={() => this.props.onCancel()}></i> : null
          }
        </div>
      </div>
    )
  }
}
