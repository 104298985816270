import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import { getConfiguration } from '../../utils'
import { TopArticlesLayoutAttributes } from '../constants'

export const HeaderComponent = ({data}) =>
  <ReactStrap.Navbar className="rasa-public-header">
    <ReactStrap.Nav className="center">
      <ReactStrap.NavItem className="center">
        {getConfiguration(data, TopArticlesLayoutAttributes.HeaderImageUrl) ?
          <img className="brand-header" src={
            getConfiguration(data, TopArticlesLayoutAttributes.HeaderImageUrl)}/> :
            getConfiguration(data, TopArticlesLayoutAttributes.BrandHeaderImageUrl) ?
          <img className="brand-header" src={
            getConfiguration(data, TopArticlesLayoutAttributes.BrandHeaderImageUrl)}/> : null}
        <div className="brand-header-text"
          dangerouslySetInnerHTML={{__html: getConfiguration(data, TopArticlesLayoutAttributes.HeaderText)}}>
        </div>
      </ReactStrap.NavItem>
    </ReactStrap.Nav>
  </ReactStrap.Navbar>
