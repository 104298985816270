import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { getConfiguration } from '../../utils'
import { TopArticlesLayoutAttributes } from '../constants'
import * as Types from '../types'

interface ReadMoreProps {
  article: Types.TopArticle,
  colClassName: string,
  configurations: any,
  rowClassName: string
}
export const ReadMoreComponent = ({article, colClassName, configurations, rowClassName}: ReadMoreProps) => {
  const readMoreColor = getConfiguration(configurations, TopArticlesLayoutAttributes.ReadMoreButtonColor)
  return <Row className={rowClassName}>
          <Col>
            <a className={colClassName}
              style={{ color: readMoreColor,
              border: '2px solid ' + readMoreColor }}
              href={article.url + '?utm_source=rasaio&utm_medium=top-articles&link=read-more'}
              target="_blank" rel="noopener noreferrer">
                {getConfiguration(configurations,
                  TopArticlesLayoutAttributes.ReadMoreButtonText)}
              </a>
          </Col>
        </Row>
}
