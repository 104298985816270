import bodybuilder from 'bodybuilder'
import { Loading } from 'components/loading'
import { NextNewsletterSendComponent } from 'components/schedule-editor/nextNewsletter'
import { RasaContext } from 'context'
import { AggregationType, DateRanges, esToNormalDateRange, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponsePayload } from 'elasticsearch/types'
import { Dataset } from 'generic/dataset'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { CountsComponent } from './counts'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import {Fields} from "../../../shared/modals";
import {ComponentType} from "react";

interface Stats {
  totalBounces?: number,
  totalDelivers?: number,
  uniqueClicks?: number,
  uniqueDelivers?: number,
  uniqueOpens?: number,
  uniqueHardBounces?: number,
}

interface Props {
  lastIssue?: any,
  nextIssue?: any
}

type WeeklyAverageProps = Props & ElasticsearchProps<Stats>

interface EngagementState {
  loaded: boolean,
  subscribedCount: number,
  unsubscribedCount: number,
}

const EVENT_AGGREGATION: string = 'event_name'
const ENGAGEMENT_AGGREGATION: string = 'engagement'
const DATE_AGGREGATION: string = 'date'

class WeeklyAverageClass extends ElasticsearchComponent<Stats, WeeklyAverageProps, EngagementState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(p: WeeklyAverageProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      subscribedCount: 0,
      unsubscribedCount: 0,
    }
  }

  public parseResponse(payload: ResponsePayload): Promise<Stats> {
    const engagementNameAggregations = payload.aggregations[ENGAGEMENT_AGGREGATION]

    const delivers = this.getAggregation(engagementNameAggregations, 'delivered')
    const opens = this.getAggregation(engagementNameAggregations, 'open')
    const clicks = this.getAggregation(engagementNameAggregations, 'click')
    const hardBounce = this.getAggregation(engagementNameAggregations, 'hard_bounce')
    const dropped = this.getAggregation(engagementNameAggregations, 'dropped')
    const spamReport = this.getAggregation(engagementNameAggregations, 'spamReport')
    const totalHardBounce = this.getAggregationTotal(hardBounce)
    const totalSpamReport = this.getAggregationTotal(spamReport)
    const totalDropped = this.getAggregationTotal(dropped)

    this.setState({
      loaded: true,
    })
    return Promise.resolve({
      totalBounces: (totalHardBounce + totalDropped + totalSpamReport),
      totalDelivers: this.getAggregationTotal(delivers),
      uniqueClicks: this.getAggregationUnique(clicks),
      uniqueDelivers: this.getAggregationUnique(delivers),
      uniqueOpens: this.getAggregationUnique(opens),
      uniqueBounces: this.getAggregationUnique(hardBounce),
    })
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
    .sort('created', 'desc')
    .filter(FilterType.range, 'message_send_date', toFilter(DateRanges.WeeklyAverage))
    .filter(FilterType.term, 'community_identifier.keyword', this.communityId)
    .filter(FilterType.term, 'include_in_reports', true)
    .filter(FilterType.term, 'message_type.keyword', 'NewsbriefMessage')

    this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'event_name.keyword',
      name: ENGAGEMENT_AGGREGATION,
      child: {
        type: AggregationType.date_histogram,
        field: 'message_send_date',
        extra: { interval: 'week' },
        name: DATE_AGGREGATION,
        child: {
          type: AggregationType.terms,
          field: 'event_name.keyword',
          name: EVENT_AGGREGATION,
          unique_on: 'community_person_id',
        },
      },
    })
    return this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'event_name.keyword',
      name: 'top_source_clicks',
      extra: { size: '10' },
    }).build()
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.communityId = activeCommunity.communityId
      this.search()
      this.loadSubscriberCount()
    })
  }

  public render = () => {
    return <div>
      {this.props.results && this.state.loaded
      ? <div className="weekly-average">
        <Row>
          <Col md="12">
            <Row>
              <Col md={6} className="stats-box">
                <NextNewsletterSendComponent
                  description="Your last newsletter was sent on"
                  timezone={null}
                  nextIssue={this.props.lastIssue}
                  showContactCount={true}>
                </NextNewsletterSendComponent>
              </Col>
              <Col className="counts-component-container opensbox metric-box stats-box">
                <CountsComponent
                  name="UNIQUE OPEN RATE"
                  sent={this.props.results.uniqueDelivers}
                  unique={this.props.results.uniqueOpens} />
              </Col>
              <Col className="counts-component-container deliveriesbox metric-box stats-box">
                <CountsComponent name="SUBSCRIBERS"
                  total={this.state.subscribedCount} />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="stats-box">
                <NextNewsletterSendComponent
                  timezone={null}
                  nextIssue={this.props.nextIssue}
                  showContactCount={true}>
                </NextNewsletterSendComponent>
              </Col>
              <Col className="counts-component-container clicksbox metric-box stats-box">
                <CountsComponent name="UNIQUE CLICK RATE"
                  sent={this.props.results.uniqueDelivers}
                  unique={this.props.results.uniqueClicks} />
              </Col>
              <Col className="counts-component-container deliveriesbox metric-box stats-box">
                <CountsComponent name="BOUNCE RATE"
                  sent={this.props.results.totalDelivers + this.props.results.totalBounces}
                  unique={this.props.results.totalBounces} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      : <Loading size="64" />
      }
  </div>
  }
  private getAverageNewsLettersSent = (issuesArray) =>
    issuesArray.length > 0 ?
      Math.floor(issuesArray.map((issue) => issue.expected).reduce((a, b) => a + b, 0) / issuesArray.length)
    : 0
  private loadSubscriberCount = () => {
    const dateRanges = esToNormalDateRange(DateRanges.WeeklyAverage).split('|')
    const params = [
      {param: 'minimumSent', value: '0'},
      {param: 'startDate', value: dateRanges[0]},
      {param: 'pageSize', value: 100},
    ]
    return new Dataset().loadCommunityDataset('communityIssues', this.communityId, params)
      .then((communityIssues) => {
        if (communityIssues[0]) {
          this.setState({
            subscribedCount: this.getAverageNewsLettersSent(communityIssues[0]),
          })
        }
      })
  }

  private getAggregationTotal = (aggregation: any) => {
    return aggregation ? aggregation.doc_count : 0
  }

  private getAggregationUnique = (aggregation: any) => {
    return aggregation ? aggregation.child.buckets
      .map((a) => a.child.buckets.length ? a.child.buckets[0].unique.value : 0).reduce((a, b) => a + b) : 0
  }
}

export const AnalyticsWeeklyAverageComponent: ConnectedComponentClass<ComponentType<WeeklyAverageClass>, Fields> = GenericRedux.registerNewComponent(
  WeeklyAverageClass, 'analytics_weekly_average', {},
)
