import React from 'react'

export const RasaContext = React.createContext({
  entityMetadata: null,
  user: null,
  store$: null,
  history$: null,
  store: null,
  action$: null,
  combined$: null,
})
