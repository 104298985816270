import { DropdownOption } from 'components/dropdown/component'
import { HeaderLogo } from 'components/icons/menu-header-logo'
import { SettingsIcon} from 'components/icons/settings'
import React from 'react'
import * as Types from '../types'
import * as Utils from '../utils'

export const SIGNUP_TAB_SELECTED = 'TAB_SELECTED'

export enum SignupLayoutAttributes {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  BannerPlacement = 'signup_banner_placement',
  ShowHeader = 'signup_header_show',
  HeaderImageUrl = 'signup_header_image_url',
  HeaderText = 'signup_header_text',
  SignupLeadText = 'signup_lead_text',
  SignupButtonText = 'signup_button_text',
  SignupButtonColor = 'signup_button_color',
  SidebarImageUrl = 'signup_sidebar_image_url',
  BrandHeaderImageUrl = 'brand_header_image_url',
}

export enum PrivacyLayoutAttributes {
  PrivacyLeadText = 'privacy_lead_text',
}

export const REDUX_STORE_HOME = 'signup_layout'

export const COMPANY_NAME = '__COMPANY_NAME__'

export enum MenuItems {
  headerAndLogo = 'Header Logo',
  cta = 'CTA',
  privacy = 'Privacy'
}

export const MenuSections: Types.MenuSection[] = [
  {
    icon: <HeaderLogo />,
    // key: 'headerAndLogo',
    key: Utils.getEnumHash(MenuItems.headerAndLogo, MenuItems),
    value: MenuItems.headerAndLogo,
  },
  {
    icon: <SettingsIcon svgwidth={27.5} svgheight={27.5} />,
    // key: 'signup',
    key: Utils.getEnumHash(MenuItems.cta, MenuItems),
    value: MenuItems.cta,
  },
  {
    icon: <SettingsIcon svgwidth={27.5} svgheight={27.5} />,
    key: Utils.getEnumHash(MenuItems.privacy, MenuItems),
    value: MenuItems.privacy,
  },
]

export enum BannerPlacement {
  // Important that default also be 0 - so "not specified" is same as "0" as "falsey" values
  // And these should be strings - since that's what the underlying properties are
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export const bannerPlacementDropdownOptions: DropdownOption[] = [
  {
    description: BannerPlacement.TOP,
    key: BannerPlacement.TOP,
    value: BannerPlacement.TOP,
  },
  {
    description: BannerPlacement.BOTTOM,
    key: BannerPlacement.BOTTOM,
    value: BannerPlacement.BOTTOM,
  },
  {
    description: BannerPlacement.LEFT,
    key: BannerPlacement.LEFT,
    value: BannerPlacement.LEFT,
  },
  {
    description: BannerPlacement.RIGHT,
    key: BannerPlacement.RIGHT,
    value: BannerPlacement.RIGHT,
  },
]
