import { format } from 'date-fns'
import { DateRanges } from 'elasticsearch/constants'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import React from 'react'
import { OpenLinkIcon } from '../icons/openlink'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { copyToClipboard, getEnvironmentSuffix } from '../../generic/utility'

interface FormLinkComponentProps {
  name: string,
  description: any,
  disabled?: boolean,
  link: string,
  inputLabel?: string,
  isRecommended?: boolean,
  hideLinkOpen?: boolean,
}

export const RASA_FEED_URL = 'http://rasa.io/feed'


export const abbreviateNumber = (value: number) => {
  if (value < 10000) {
    return value
  }
  const suffixes = ["", "K", "M", "B", "T"]
  const suffixNum = Math.floor(("" + value).length / 3)
  const shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toFixed(2))
  return shortValue.toString() + suffixes[suffixNum]
}

export const getConfiguration = (
  configurations: any,
  key: any,
  defaultValue?: any,
): string => {
  return configurations[key] ? configurations[key] : defaultValue
}

export const getEnumHash = (key: string, enumList: any): string => {
  const match = Object.keys(enumList).filter((x) => enumList[x] === key)[0]
  return match ? match : ''
}

export const cleanText = (text: string): string => {
  if (text != null) {
    return text.replace(/(^\s*)|(\s*$)/gi, '') /*exclude  start and end white-space */
    .replace(/[ ]{2,}/gi, ' ') /* 2 or more space to 1 */
    .replace(/\n /, '\n') /* newlines */
  } else {
    return text
  }
}

export const FormLinkComponent = (props: FormLinkComponentProps) =>
  <div className="form-link">
    <div className="left-part">
      <div className="first-line">
        <div className="name">{props.name}</div>
        {props.isRecommended && <div className="recommended">RECOMMENDED</div>}
      </div>
      <div className="description" key={props.description}>
        {props.description.map((el, i) => {
          if (typeof el === 'string') {
            return el
          }
          return React.cloneElement(el, { key: i })
        })}
      </div>
    </div>
    <div className="middle-part">
      {!props.hideLinkOpen && <a target="_blank" href={props.link} className="open-link"><OpenLinkIcon /></a>}
    </div>
    <div className="right-part">
      <div className={`input ${props.disabled ? 'element-disabled' : ''}`}>
        <input type="text" value={props.link} readOnly={true} />
        <button onClick={() => copyToClipboard(props.link)}>Copy</button>
      </div>
      <div className="input-label">{props.inputLabel}</div>
    </div>
  </div>

export const getShortText = (text: string, length: number): string => {
  if ( text == null ) {
    return ''
  } else {
    const ct = cleanText(text)
    if (ct.length < length) {
      return ct
    } else {
      const shorter = ct.substring(0, length)
      const words = shorter.split(' ')
      return words.slice(0, words.length - 1).join(' ') + '...'
    }
  }
}

const widgetsJSON = {
  components: [{
    colMd: '7',
    rows:
    [
      {
        component: 'NewsletterStatsComponent',
        title: '',
        props: {
          title: 'Newsletter Stats',
        },
      },
      {
        component: 'TopTopicsComponent',
        title: '',
        props: {
          title: 'Topics',
        },
      },
      {
        component: 'Statistics',
        title: '',
      },
      {
        component: 'AnalyticsOpensClicksComponent',
        title: 'Monthly Snapshot',
        props: {
          dateRange: DateRanges.PastMonth,
          selectedInterval: 'week',
          selectedSegmentCode: DEFAULT_COMMUNITY_PARTNER_CODES.ALL,
          hideGrid: true,
        },
      },
    ],
  },
  {
    colMd: '5',
    rows: [
      {
        component: 'WidgetTopArticles',
        title: '',
        props: {
          height: '4350px',
          title: 'Top Articles',
      },
      },
    ],
  }],
}
export const getWidgetsList = (): Promise<any> => {
  return Promise.resolve(widgetsJSON.components)
}

export const getDummyViewInBrowserLink = (communityId: string): any => {
  return `https://pages${getEnvironmentSuffix()}.rasa.io/newsbrief/${communityId}?date=yyyy-mm-dd`
}

export const getViewInBrowserUrl = (communityId: string, send_at: any): string => {
  const formatedDate = format(new Date(send_at), DEFAULT_DATE_FORMAT)
  return `https://pages${getEnvironmentSuffix()}.rasa.io/newsbrief/${communityId}?date=${formatedDate}`
}
