import { SelectColor } from 'components/colors/component'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Input } from 'reactstrap'
import { getConfiguration } from '../../utils'
import { DateRangesAsDropdownOptions, TopArticlesLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'
import { DropdownComponent } from "../../../dropdown/component";

const initialState = {
  alertMessage: {
    maxCount: '',
  },
}

export class ArticleSettings extends RasaReactComponent<EditSectionProps, any> {
  constructor(props: EditSectionProps) {
    super(props, 'countsEdit', initialState)
  }
  public render() {
    return <div className="counts-editor">
      <span className="title">Article Counts</span>
      <div className="section">
        <div className="block">
          <div className="title">
            Maximum article count
          </div>
          <div className="description">
            What is the maximum number of articles that you want to display?
          </div>
          <Input value={getConfiguration(this.props.data,
                        TopArticlesLayoutAttributes.ArticlesMaxCount)}
                 max={15}
                 min={5}
                 type="number"
                 name={TopArticlesLayoutAttributes.ArticlesMaxCount}
                 onChange={(e) => {
                   this.props.onChange(TopArticlesLayoutAttributes.ArticlesMaxCount,
                   e.target.value)}}/>
          <div className="alert-message">
            {this.state.alertMessage.maxCount}
          </div>
        </div>
        <div className="block">
          <div>
            <div className="title">
              Date
            </div>
            <div className="description">
              Number of articles to display Date range
            </div>
            <DropdownComponent data={DateRangesAsDropdownOptions}
                               selected={this.findDateRange()}
                               onChange={(e) => {
                                 this.props.onChange(TopArticlesLayoutAttributes.TopArticlesDate, e.selected.value)
                               }}/>
          </div>
        </div>
        <div className="section">
          <div className="block">
            <div className="title">Read More</div>
            <div>
              <span>Text</span>
              <Input value={
                getConfiguration(this.props.data, TopArticlesLayoutAttributes.ReadMoreButtonText)}
              maxLength={50}
              onChange={(e) => {
                this.props.onChange(TopArticlesLayoutAttributes.ReadMoreButtonText, e.target.value)
              }}/>
          </div>
          <div>
            <span>Color</span>
            <SelectColor
              key={TopArticlesLayoutAttributes.ReadMoreButtonColor}
              className={'secondary'}
              selectColor={(c: string) => {
                this.props.onChange(TopArticlesLayoutAttributes.ReadMoreButtonColor, c)}}
              color={getConfiguration(this.props.data,
                TopArticlesLayoutAttributes.ReadMoreButtonColor, '#000000')} />
          </div>
        </div>
      </div>
      </div>
    </div>
  }

  private findDateRange = (): number | string => {
    const topArticlesDate = getConfiguration(this.props.data, TopArticlesLayoutAttributes.TopArticlesDate)
    const option = DateRangesAsDropdownOptions.find(x => x.value === topArticlesDate)
    if(option !== undefined){
      return option.key
    }
    return DateRangesAsDropdownOptions[0].key
  }
}
