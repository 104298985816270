import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import './styles.css'
import { Editor as TinyMCEEditor, TinyMCE} from 'tinymce'

interface TinymceEditorProps {
  html?: string,
  onChange: any,
  tinymceConfig?: TinymceEditorOptions,
  height?: string,
  instance?: (editor: TinyMCEEditor) => void
  showCharacterCount?: boolean
  maxCharacterCount?: number
}
const TINY_MCE_DEFAULT_HEIGHT = '413'

const stripHTML = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").length
}

export type TinymceEditorOptions = Parameters<TinyMCE['init']>[0] & {
  selector?: undefined;
  target?: undefined;
};

export const Tinymce_Default_Config: TinymceEditorOptions = {
  plugins: [
    'lists', 'link', 'charmap', 'preview', 'emoticons',
    'searchreplace', 'code', 'fullscreen',
    'insertdatetime', 'table', 'image',
  ],
  default_link_target: '_blank',
  link_default_target: '_blank',
  link_default_protocol: 'https',
  toolbar: `undo redo | styles | bold italic | fontsize | alignleft aligncenter
            alignright alignjustify | forecolor backcolor | bullist numlist outdent indent |
            numlist bullist | emoticons | link image | preview code `,
  emoticons_append: {
    custom_mind_explode: {
      keywords: ['brain', 'mind', 'explode', 'blown'],
      char: '🤯',
    }},
  height: TINY_MCE_DEFAULT_HEIGHT,
  statusbar: false,
  menu: {
    file: { title: '', items: '' },
    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
    view: { title: 'View', items: 'code' },
    insert: { title: 'Insert', items: 'link inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents ' },
    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
    table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
    tools: { title: '', items: '' },
    // help: { title: 'Help', items: 'help' },
  },
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  // noneditable_noneditable_class: 'mceNonEditable',
  toolbar_mode: 'sliding',
  spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
  tinycomments_mode: 'embedded',
  contextmenu: 'link table',
  a11y_advanced_options: true,
  skin: 'oxide',
  content_css: 'default',
  quickbars_image_toolbar: false,
  link_target_list: [{ title: 'New Window', value: '_blank' }],
  image_advtab: true,
  image_caption: true,
  image_title: true,
  image_description: false,
  promotion: false,
  branding: false,
}

export const TinymceEditorComponent = (props: TinymceEditorProps) => {
  const editorRef = useRef(null)
  const [characterCount, setCharacterCount] = useState(props.html ? stripHTML(props.html) : 0)
  const handleEditorChange = (content: string) => {
    setCharacterCount(stripHTML(content))
  }

  const tinyMCEOptions: any = {
    ...(props.tinymceConfig !== undefined ? props.tinymceConfig : Tinymce_Default_Config),
    height: props.height ? props.height : TINY_MCE_DEFAULT_HEIGHT,
  }

  return (
    <>
      <Editor
        tinymceScriptSrc='https://assets.rasa.io/tinymce/tinymce.min.js'
        onInit={(evt, editor: TinyMCEEditor) => {
          editorRef.current = editor
          if(props.instance) {
            props.instance(editor)
          }
        }}
        onEditorChange={(evt, editor) => {
          handleEditorChange(editor.getContent())
        }}
        onBlur={(evt, editor) => props.onChange(editor.getContent())}
        initialValue={props.html}
        init={tinyMCEOptions}
    />
    {
      props.showCharacterCount ?
      <div className="description-character-count">
        Character Count: {characterCount}
        {props.maxCharacterCount && characterCount > props.maxCharacterCount ? <div className="character-count-error"> Max limit ({props.maxCharacterCount}) Exceeded</div> : null}
      </div> : null
    }
  </>
  );
}
