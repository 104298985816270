import { SelectColor } from 'components/colors/component'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { stripHTML } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { getConfiguration } from '../../utils'
import { UnsubscribeLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'

export class ResubscribeSettings extends React.Component<EditSectionProps> {
  constructor(props: EditSectionProps) {
    super(props)
  }

  public render() {
    return <div className="logo-size">
      <span className="title">{this.props.name}</span>
      <div className="section">
        <div className="block">
        <div className="title">Resubscribe Header</div>
          <div className="resubscribe_header_text">
            <span>Text</span>
            <WysiwygEditor
              htmlEdited={false}
              defaulFontSize = "26px"
              defaultFonSizeClass="wysiwyg-editor-default-font"
              html={this.getResubscribeHeaderText()}
              onChange={(html: string, isHtmlEdited: boolean) => {
                this.props.onChange(UnsubscribeLayoutAttributes.ResubscribeHeaderTextHtmlEdited, isHtmlEdited)
                if (stripHTML(html) === '') {
                  this.props.onChange(UnsubscribeLayoutAttributes.ResubscribeHeaderText, '')
                } else {
                  this.props.onChange(UnsubscribeLayoutAttributes.ResubscribeHeaderText, html)
                }
              }}/>
          </div>
        </div>
      </div>
      <div className="section">
      <div className="block">
          <div className="title">Resubscribe Button</div>
          <div>
            <span>Text</span>
            <Input value={
              getConfiguration(this.props.data, UnsubscribeLayoutAttributes.ResubscribeButtonText)}
              maxLength={100}
              onChange={(e) => {
                this.props.onChange(UnsubscribeLayoutAttributes.ResubscribeButtonText, e.target.value)
              }}/>
          </div>
          <div>
            <span>Color</span>
            <SelectColor
              key={UnsubscribeLayoutAttributes.ResubscribeButtonColor}
              className={'secondary'}
              selectColor={(c: string) => {
                this.props.onChange(UnsubscribeLayoutAttributes.ResubscribeButtonColor, c)}}
              color={getConfiguration(this.props.data,
                UnsubscribeLayoutAttributes.ResubscribeButtonColor, '#000000')} />
          </div>
        </div>
      </div>
    </div>
  }

  private getResubscribeHeaderText = (): any => {
    const scheduleHeaderrText = getConfiguration(this.props.data, UnsubscribeLayoutAttributes.ResubscribeHeaderText)
    getConfiguration(this.props.data, UnsubscribeLayoutAttributes.ResubscribeHeaderTextHtmlEdited)
    return scheduleHeaderrText ? scheduleHeaderrText : ''
  }

}
