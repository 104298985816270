import * as React from 'react'
import { Resubscribe } from './layouts/resubscribe'
import { Unsubscribe } from './layouts/unsubscribe'
import * as Types from './types'
import { MenuItems } from './constants'

export const PreviewComponent = ({configurations, data, select}: Types.WrappedState) => {
  return <div>
    <div className="page-header">Unsubscribe</div>
    <Unsubscribe data={data} />
    <hr className="row-separator" />
    {select && select.value === MenuItems.confirmation && <>
    <div className="page-header">Unsubscribe Confirmation</div>
    <Resubscribe data={data} />
      </> }
  </div>
}
