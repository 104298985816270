import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'
import { HeaderComponent } from './headerComponent'

export const SignupForm = ({data}) => {
  const text = Utils.getConfiguration(data, Constants.PrivacyLayoutAttributes.PrivacyLeadText)
  return <ReactStrap.Col>
  <HeaderComponent data={data}/>
  <ReactStrap.Container>
    <ReactStrap.Card>
      <ReactStrap.CardBody>
        <ReactStrap.Input
          type="email"
          placeholder="Email Address (required)"/>
        <br/>
        <ReactStrap.Input
          placeholder="First Name"/>
        <br/>
        <ReactStrap.Input
          placeholder="Last Name"/>
      </ReactStrap.CardBody>
    </ReactStrap.Card>
      <br/>
      <ReactStrap.CardText>
        { text ? <span dangerouslySetInnerHTML={{
        __html: text,
      }}/> : <span></span> }
      </ReactStrap.CardText>
    <ReactStrap.Container className="rasa-submit-btn">
      <ReactStrap.Button
          style={{backgroundColor: Utils.getConfiguration(data,
            Constants.SignupLayoutAttributes.SignupButtonColor, '')}}
          disabled={true}
          className="rasa-submit-btn signup-btn"
          size="lg">
          {Utils.getConfiguration(data, Constants.SignupLayoutAttributes.SignupButtonText)}
      </ReactStrap.Button>
    </ReactStrap.Container>
  </ReactStrap.Container>
</ReactStrap.Col>
}
