import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { ImageUpload } from 'components/image-upload/component'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'
import { getConfiguration } from '../../utils'
import { TopArticlesLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'

interface HeaderSettingsState {
  error: boolean,
  loadingImage: boolean
}
export class HeaderSettings extends React.Component<EditSectionProps, HeaderSettingsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: EditSectionProps) {
    super(props)
    this.state = {
      error: false,
      loadingImage: false,
    }
    this.uploadImage = this.uploadImage.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.communityId = activeCommunity.communityId
    })
  }

  public render() {
    const isShowHeader: boolean =
      isTruthy(getConfiguration(this.props.data, TopArticlesLayoutAttributes.ShowHeader))
    return <div className="logo-size">
      <span className="title">Header</span>
      <div className="section">
        <div className="block">
          <div className="toggle-active"
               onClick={() => {
                 this.props.onChange(TopArticlesLayoutAttributes.ShowHeader, !isShowHeader)
                }}>
            <button className="toggle-button"
                    style={{background: 'none', border: 'none', outline: 'none'}}>
              {isShowHeader ? <ToggleOn/> : <ToggleOff /> }
            </button>
            <span className="small-description">Show/Hide</span>
          </div>
        </div>
      </div>
      <br />
      <div className="section">
        <div className="small-title">
          Header Text
        </div>
        <WysiwygEditor
          htmlEdited={false}
          html={this.getHeaderText()}
          onChange={(html: string, isHtmlEdited: boolean) => {
            this.props.onChange(TopArticlesLayoutAttributes.HeaderText, html)
          }}/>
      </div>
      <div className="section">
        <ImageUpload imageUrl={getConfiguration(this.props.data, TopArticlesLayoutAttributes.HeaderImageUrl)}
          showCloseButton={true} closeButtonClick={() => this.props.onChange(
            TopArticlesLayoutAttributes.HeaderImageUrl, '')}
          uploadImage={this.uploadImage} />
      </div>
    </div>
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.setState({
          loadingImage: false,
        })
        this.props.onChange(TopArticlesLayoutAttributes.HeaderImageUrl, hostedImage.url)
      })
      .catch((error) => {
        this.setState({
          error: true,
          loadingImage: false,
        })
      })
  }

  private getHeaderText = (): any => {
    const headerText = getConfiguration(this.props.data, TopArticlesLayoutAttributes.HeaderText)
    return headerText ? headerText : ''
  }
}
