import * as React from 'react'
import { Col, Row } from 'reactstrap'
import * as Types from '../types'

interface ReadCountProps {
  article: Types.TopArticle,
  countClassName: string,
}
export const ReadCountComponent = ({article, countClassName}: ReadCountProps) => {
  const count = article.count || article.unique_count
  return <Row>
          <Col className={countClassName}>
          { count >= 10 ? 'READ ' + count + ' TIMES' : null }
          </Col>
        </Row>
}
