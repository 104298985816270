import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import * as Utils from './../utils'
import * as Constants from './constants'
import { Sidebar } from './layouts/sidebar'
import { SignupForm } from './layouts/signupForm'
import * as Types from './types'
import { isEmpty } from 'lodash'

export const PreviewComponent = ({configurations, data}: Types.WrappedState) => {
  if (configurations && !isEmpty(configurations)) {
    switch (Utils.getConfiguration(data, Constants.SignupLayoutAttributes.BannerPlacement).toLowerCase().trim()) {
      case 'top':
        return <ReactStrap.Container>
                <ReactStrap.Row><Sidebar data={data}></Sidebar></ReactStrap.Row>
                <ReactStrap.Row>
                  <SignupForm data={data} />
                </ReactStrap.Row>
              </ReactStrap.Container>
      case 'bottom':
        return <ReactStrap.Container>
              <ReactStrap.Row>
                <SignupForm data={data} />
              </ReactStrap.Row>
              <ReactStrap.Row><Sidebar data={data}></Sidebar></ReactStrap.Row>
            </ReactStrap.Container>
      case 'left':
        return <ReactStrap.Container>
                <ReactStrap.Row>
                  <Sidebar data={data}></Sidebar>
                  <SignupForm data={data} />
                </ReactStrap.Row>
              </ReactStrap.Container>
      default:
        return <ReactStrap.Container>
                <ReactStrap.Row>
                  <SignupForm data={data} />
                  <Sidebar data={data}></Sidebar>
                </ReactStrap.Row>
              </ReactStrap.Container>
    }
  } else {
    return <div></div>
  }
}
