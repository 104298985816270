import { sizedUrl } from 'app/utils'
import * as React from 'react'
import {Col, Row} from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'
import * as Types from '../types'
import {ReadCountComponent} from './readCountComponent'
import {ReadMoreComponent} from './readMoreComponent'

export const LeadArticleCard = ({article, configurations}: Types.ArticleProps) => {
  return <div className="d-lead-article-rectangle">
    <Row>
      <Col md="7" className="d-lead-article-top-part">
        <Row>
          <Col className="d-lead-article-title">
            <a id="track-url" href={article.url + '?utm_source=rasaio&utm_medium=top-articles&link=title'}
               target="_blank" rel="noopener noreferrer">
              {Utils.getShortText(article.title, Constants.SHORT_TITLE_LENGTH)}
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="d-article-source">{article.site_name}</Col>
        </Row>
        <Row>
          <Col className="d-lead-article-description">
            {Utils.getShortText(article.description, Constants.SHORT_DESCRIPTION_LENGTH)}
          </Col>
        </Row>
        <ReadMoreComponent article={article}
          configurations={configurations}
          rowClassName={'d-lead-article-read-more-row'}
          colClassName={'d-lead-article-read-more'} />
      </Col>
      <Col md="5">
        <Row>
          <Col className="d-lead-article-image image">
            <a id="track-url" href={article.url + '?utm_source=rasaio&utm_medium=top-articles&link=image'}
               target="_blank" rel="noopener noreferrer">
              <img src={sizedUrl(article.hosted_image_url,
                                                   Constants.DESKTOP_LEAD_IMAGE_SIZE,
                                                   Constants.DESKTOP_LEAD_IMAGE_SIZE)} /></a>
          </Col>
        </Row>
        <ReadCountComponent article={article} countClassName={'d-lead-article-click-count'} />
      </Col>
    </Row>
  </div>
}
