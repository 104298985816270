export const brandCyan = '#58C4C7'
export const brandCyanLight = '#84D9DE'
export const brandBrown = '#593D3C'
export const brandGrey = '#EAEAEA'
export const brandRed = '#D85C58'
export const brandYellow = '#E4AB4F'
export const textDarkGrey = '#425866'
export const textMediumGrey = '#748B99'
export const textLightGrey = '#EBEFF2'
export const textLightBlue = '#5380A9'
export const uiBlue = '#005EB8'
export const uiDarkGrey = '#425866'
export const uiMediumGrey = '#748B99'
export const uiLightGrey = '#C6C3CC'
export const uiRed = '#E53631'
export const uiError = '#F34336'
export const uiSuccess = '#7FAE1B'
export const uiLightError = '#FFECEC'
export const colorWhite = '#FAFAFA'
export const uiLighterGrey = '#f6f6f6'
export const uiBlueGrey = '#59717f'
export const coral = '#FF6B6B'
export const cyan = '#4ECDC4'
export const cyan100 = '#e4f2f2'
export const cyan200 = '#bee9eb'
export const cyan300 = '#8ae0e3'
export const cyan400 = '#58c4c7'
export const cyan500 = '#319699'
export const cyan600 = '#135e61'
export const chocolate100 = '#d1c0c0'
export const chocolate200 = '#9e8688'
export const chocolate300 = '#6e5657'
export const chocolate400 = '#462e2f'
export const chocolate500 = '#261415'
export const darkBlue = '#004F6D'
export const yellow100 = '#f9faaf'
export const yellow200 = '#e6e962'
export const yellow300 = '#c5c740'
export const yellow400 = '#929421'
export const yellow500 = '#62630c'
export const green100 = '#cce695'
export const green200 = '#abd15a'
export const green300 = '#7fae1b'
export const green400 = '#56780c'
export const green500 = '#314700'
export const red100 = '#ffe6e6'
export const red200 = '#ffd3d1'
export const red300 = '#fc9d9a'
export const red400 = '#d85c58'
export const red500 = '#b8312c'
export const red600 = '#8c201c'
export const red700 = '#690300'
export const grey100 = '#fafcfc'
export const grey200 = '#f0f7f7'
export const grey300 = '#dae3e3'
export const grey400 = '#b9c7c7'
export const grey500 = '#8d9e9e'
export const grey600 = '#667878'
export const grey700 = '#3b4a4a'
export const grey800 = '#223030'
export const grey900 = '#0f1a1a'
