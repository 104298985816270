import * as React from 'react'
import {Input, Label} from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'
import * as Types from '../types'

export const TermsComponent = ({topArticles}: Types.WrappedState) => {
  return <Label check>
          <Input type="checkbox"
            className="article-signup-terms-chk"
            checked={true} />
          <span>I agree to
            <a href={Utils.getConfiguration(topArticles.configurations,
              Constants.TopArticlesLayoutAttributes.TermsUrl)} target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </span>
        </Label>
}
