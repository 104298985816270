import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import * as Redux from 'redux'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import {injectReducer} from 'store/index'
import { PreviewWidgetMenuComponent } from '../previewMenuWidget'
import '../styles.css'
import { MenuSection } from '../types'
import {
  DELIMITER,
  MenuItems,
  MenuSections,
  REDUX_STORE_HOME, TopArticlesLayoutAttributes,
} from './constants'

import { ArticleSettings } from './layouts/articleSettings'
import { HeaderSettings } from './layouts/headerSettings'
import { SignupSettings } from './layouts/signupSettings'
import { PreviewComponent } from './preview'
import './style.scss'
import * as Types from './types'
import { newsletterWhitelistDomains, WhitelistDomains } from '../../../whitelist-domain/utils'
import { getEnvironmentSuffix } from '../../../generic/utility'
import {FormLinkComponent, getConfiguration} from '../utils'
import * as GlobalConstants from '../../../constants'

type WidgetsTopArticlesComponentProps = GenericRedux.AllComponentProps<any>
interface WidgetsTopArticlesComponentState {
  communityId: string,
  communityRecordId: string,
  communityGuid: string,
  articlesLoading: boolean,
  context?: any,
  selected?: MenuSection,
  topArticles: Types.TopArticlesComponentState,
  newsletterDomains: WhitelistDomains,
  topArticleUrl: string,
}

class WidgetsTopArticlesComponent extends RasaReactComponent<WidgetsTopArticlesComponentProps,
      WidgetsTopArticlesComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: WidgetsTopArticlesComponentProps) {
    super(props, 'widgetsLayout', {
      communityId: '',
      communityRecordId: '',
      communityGuid: '',
      selected: null,
      articlesLoading: true,
      topArticles: {
        articles: [],
        isLoading: true,
        configurations: {},
      },
      newsletterDomains: {},
      topArticleUrl: "",
    })
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.hash),
      this.sharedStore.getValue(SharedKeys.person)
    ])
    .then(([activeCommunity, hash, person]) => {
      this.loadRecord(activeCommunity.communityId, activeCommunity.data.community_id)
      .then(() => {
        this.setState({
          communityId: activeCommunity.communityId,
          communityRecordId: activeCommunity.data.community_id,
          communityGuid: activeCommunity.data.community_guid,
          topArticles: {
            ...this.state.topArticles,
            configurations: this.props.data,
            communityName: activeCommunity.data.community_name,
          },
        }, () => {
          this.topArticleUrl()
        })
        this.getArticleList().then((articleList) => {
          this.setState({
            topArticles: {
              ...this.state.topArticles,
              articles: articleList,
              isLoading: false,
            },
            articlesLoading: false,
          })
        })
        newsletterWhitelistDomains((person.accountInfo || {}).account_guid, activeCommunity.communityId)
          .then((newsletterDomains: WhitelistDomains) => {
            this.setState({
              newsletterDomains,
            })
          })
      })
    })
  }

  public componentDidUpdate(prevProps: any) {
    if(prevProps.data.top_articles_date !== undefined &&
      this.props.data.top_articles_date !== prevProps.data.top_articles_date)
    {
      this.topArticleUrl()
    }
  }

  public render() {
    return (
      <div className="top-articles-design">
      {this.state.articlesLoading ? <Loading size="64" /> :
        <>
          <div className="widgets-tools-forms">
            <FormLinkComponent
              name="Top Articles Page"
              description={['This is your personalized landing page that will dynamically update to showcase ' +
              'the most popular articles from your newsletter. Share this URL on other channels or ',
                <a href={GlobalConstants.RASA_HELP_EMBED_TOP_ARTICLE}
                   target="_blank"
                   rel="noopener noreferrer">learn how to embed it on your website here.</a>]}
              link={this.state.topArticleUrl}
              isRecommended={false}/>
          </div>
          <div className="top-articles-container">
            {this.state.topArticles.articles && this.state.topArticles.articles.length > 0 ?
              <div className="preview">
                <div className="top-articles-body"
                     style={{backgroundColor: '#FFFFFF', margin: '0px'}}>
                  <PreviewComponent topArticles={this.state.topArticles}
                                    data={this.props.data}/>
                </div>
              </div> : null}
            <div className="settings">

              <div className="designbuttonwrap">
                <div className={this.buttonClassNames(this.isDirty)}
                     onClick={(e) => {
                       this.saveRecord(this.state.communityRecordId)
                     }}>
                  Save
                </div>
              </div>
              <div className="editor">
                {this.getEditor()}
              </div>
            </div>
            <div className="menu">
              <PreviewWidgetMenuComponent selectItem={this.selectItem}
                                          saveRecord={this.saveRecord} isDirty={this.isDirty}
                                          menuSections={MenuSections}>
              </PreviewWidgetMenuComponent>
            </div>
          </div>
        </>
      }
      </div>
    )
  }

  private getArticleList = () => {
    return new Dataset().loadCommunityDataset('communityUpcomingArticles', this.state.communityId)
      .then((r1) => {
        const articleList = r1[0].filter((e: any) => e.is_active)
        if (articleList.length === 0) {
          return new Dataset().loadCommunityDataset('communityArticleHistory', this.state.communityId, null, 50)
            .then((r2) => r2[0])
        } else {
          return articleList
        }
      })
  }
  private buttonClassNames = (enabled: boolean): string => {
    return enabled ? 'save-button save-button-enabled clickable-item' :
                     'save-button save-button-disabled'
  }
  private selectItem = (item: MenuSection) => {
    this.setState({
      selected: item,
    })
  }
  private getEditor = () => {
    if (this.state.selected) {
      switch (this.state.selected.value) {
        case MenuItems.cta:
          return <ArticleSettings data={this.props.data}
                    name={MenuItems.cta}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
        case MenuItems.headerAndLogo:
          return <HeaderSettings data={this.props.data}
                    name={MenuItems.headerAndLogo}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
        case MenuItems.signup:
          return <SignupSettings data={this.props.data}
                    name={MenuItems.signup}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}
          />
      }
    }
    return <div>No Editor Found</div>
  }

  private topArticleUrl = () => {
    const baseUrl = `${this.pagesWebsite()}/articles/${this.state.communityGuid}?format=rss`
    const dateRange = getConfiguration(this.props.data, TopArticlesLayoutAttributes.TopArticlesDate)

    if(!dateRange){
      this.setState({
        topArticleUrl: baseUrl
      })
      return baseUrl
    }

    const dateRangeSplitted = dateRange.split(DELIMITER)
    this.setState({
      topArticleUrl: `${baseUrl}&start_date=${dateRangeSplitted[0]}&end_date=${dateRangeSplitted[1]}`
    })
  }

  private pagesWebsite = (): string => {
    if (this.state.newsletterDomains.pages && this.state.newsletterDomains.pages.alternate_domain) {
      return `https://${this.state.newsletterDomains.pages.alternate_domain}`
    } else {
      return `https://pages${getEnvironmentSuffix()}.rasa.io`
    }
  }

}

export const WidgetsTopArticles = GenericRedux.createConnect(WidgetsTopArticlesComponent, REDUX_STORE_HOME)
injectReducer(
  REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(REDUX_STORE_HOME, {}),
  }),
)
