import { format, parseISO } from 'date-fns'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import {Col, Container, Row} from 'reactstrap'
import * as Utils from '../utils'
import * as Constants from './constants'
import {HeaderComponent} from './layouts/headerComponent'
import { LeadArticleCard } from './layouts/leadArticleCardComponent'
import { RegularArticleCard } from './layouts/regularArticleCardComponent'
import { SignupComponent } from './layouts/signupComponent'
import * as Types from './types'

const IssueDate = ({topArticles}: Types.WrappedState) => {
  const issueDate = topArticles.articles.length > 0
    ? format(parseISO(topArticles.articles[0].created), 'cccc, MMM dd, yyyy') : ''
  return <div className="article-issue-date">
    {issueDate}
  </div>
}

export const PreviewComponent = ({topArticles, data}: Types.WrappedState) => {
  return !data ? null : <div>
      <Container>
        <div className={'d-none d-lg-block'}>
          <Row className="header-row">
            <Col lg="6"></Col>
            <Col lg="6" className="d-article-issue-date-col">
              { topArticles.iframe ? null :
                <IssueDate topArticles={topArticles}/>
                }
            </Col>
          </Row>
          <Row>
            <Col>
              { topArticles.iframe ||
               !isTruthy(Utils.getConfiguration(data, Constants.TopArticlesLayoutAttributes.ShowHeader))
               ? null :
              <HeaderComponent data={data} />}
                { topArticles.isLoading ?
                <div className="text-center">
                  <div className="lds-dual-ring">
                  </div>
                </div>
                : null }
              {topArticles.articles.slice(0, 1).map((a: Types.TopArticle) =>
                <LeadArticleCard key={a.post_id} configurations={data} article={a}/>)}
            </Col>
          </Row>
          <Row>
            {topArticles.articles.slice(1, parseInt(Utils.getConfiguration(data,
                Constants.TopArticlesLayoutAttributes.ArticlesMaxCount), 10),
              ).map((a: Types.TopArticle) =>
              <RegularArticleCard key={a.post_id} configurations={data} article={a}/>)}
          </Row>
        </div>
      </Container>
      { topArticles.iframe ||
        !isTruthy(Utils.getConfiguration(data, Constants.TopArticlesLayoutAttributes.ShowSignup))
        ? null :
        <SignupComponent data={data} topArticles={topArticles} />
      }
    </div>
}
