import * as React from 'react'
interface CountsComponentProps {
  name: string,
  sent?: number,
  unique?: number,
  total?: string | number,
}
export const CountsComponent = (props: CountsComponentProps) => <div>
  <div className="big-numbers chart">
    <span className="title">{props.name}</span>
    <p className="primary">
      {props.total && props.total !== '0' ?
        props.total :
      ((props.sent || 0) > 0 ? ((props.unique || 0) / props.sent * 100) : 0).toFixed(1) + ' %'}
    </p>
  </div>
</div>
