import { SelectColor, SelectColorIcons } from 'components/colors/component'
import React from 'react'
import { Quill } from 'react-quill'
import quillEmoji from 'react-quill-emoji'
import 'react-quill-emoji/dist/quill-emoji.css'
import 'react-quill/dist/quill.snow.css'
//TODO: is there a way we can attach react handlers to this
// export const quillEditor = new Quill('.wysiwyg-editor', {
//   modules: {
//     toolbar: {
//       container: '#toolbar',
//       handlers: {
//         color:  (value) => {
//           console.log('hi!!!', value)
//           //value = window.prompt('Enter Hex Color Code');
//           Color.format('color', '#6a0dad');
//        TODO: MUST FIND A WAY TO CALL FORMAT this.quill, get the this to the editor
//         },
//       },
//     },
//     clipboard: {
//       // toggle to add extra line breaks when pasting HTML:
//       matchVisual: false,
//     },
//   },
//     formats: [
//       'header', 'font', 'size', 'color',
//       'bold', 'italic', 'underline',
//       'list', 'bullet', 'link', 'image',
//       'video', 'align'],
// })

//TODO: Leaving in case we switch to built in editor:
// toolbar: [
//   ['bold', 'italic', 'underline'],
//   [ {color: [] }, { align: [] }],
//   [{list: 'bullet'}, 'link', 'image'],
//   [{size: ['14px', '16px', '18px'] }],
// ],

const Size = Quill.import('attributors/style/size')
Size.whitelist = ['12px', '14px', '16px', '18px', '20px', '28px'];
Quill.register(Size, true);

const Align = Quill.import('attributors/style/align')
const Icons = Quill.import('ui/icons');
Icons.align.left = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
Align.whitelist = ['left', 'center', 'right', 'justify']; // add explicit 'left' option
Quill.register(Align, true);

export const modules = {
  'toolbar': {
    container: '#toolbar',
  },
  'clipboard': {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  'emoji-toolbar': true,
  'emoji-shortname': true,
}

Quill.register(
  {
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
  }, true)

export const CustomToolbar = (props: any) => (
  <div id="toolbar">
    <button className="ql-bold"/>
    <button className="ql-italic"/>
    <button className="ql-underline"/>
    <button className="ql-link"/>
    <button className="ql-emoji"/>
    <br />
    <select className="ql-size" defaultValue={props.defaulFontSize || '12px'}
    style={{width: '26%', marginRight: '10px'}}>
      <option value="12px">12px</option>
      <option value="14px">14px</option>
      <option value="16px" >16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
      <option value="28px">28px</option>
    </select>
    <select className="ql-background"></select>
    <SelectColor className="ql-color"
      icon={SelectColorIcons.ALPHABET}
      color={props.color}
      selectColor={(color) => props.colorChange(color)}/>
    <select className="ql-align">
      <option value="left"></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </select>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"/>
      <button className="ql-list" value="bullet"/>
      <button className="ql-indent" value="-1"/>
      <button className="ql-indent" value="+1"/>
    </span>
  </div>)

export const formats = [
  'header', 'font', 'size', 'color',
  'bold', 'italic', 'underline',
  'list', 'bullet', 'link', 'image',
  'video', 'align', 'emoji', 'background',
]

export const HTML_SWITCH_PROMPT = 'If you switch to text editor after adding HTML, it will remove the latest changes. Do you want to continue?'
export const HTML_SWITCH_CLEAR_PROMPT = 'If you switch to text editor after adding HTML, it will remove the HTML. Do you want to continue?'
