import { AnalyticsOpensClicksComponent } from 'components/analytics/opens-clicks'
import { BlogPostWidgetComponent } from '../blog-posts/component'
import { WidgetIframe } from '../iframe'
import { WidgetsRss } from '../rss'
import { Statistics } from '../statistics/component'
import { TopTopicsComponent } from '../top-topics/component'
import { WidgetTopArticles } from '../topArticles'
import { NewsletterStatsComponent } from '../newsletter-stats/component'

export const WidgetComponents = {
  AnalyticsOpensClicksComponent,
  Statistics,
  NewsletterStatsComponent,
  WidgetTopArticles,
  WidgetsRss,
  TopTopicsComponent,
  WidgetIframe,
  BlogPostWidgetComponent,
}
