import { TinymceEditorComponent, TinymceEditorOptions } from 'components/tinymce-editor/tinymceditor'
import * as Utils from 'generic/utility'
import { isEmpty } from 'lodash'
import React, {Component} from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import * as Constants from './constants'
import './styles.css'

interface WysiwygEditorState {
  currentColor: string,
  currentTab: number,
  htmlChanged: boolean,
  html: string,
  lastWysiwygHtml: string,
  placeholder: string,
}

interface WysiwygEditorProps {
  height?: string,
  html: string,
  htmlEdited: boolean,
  tinymceConfig?: TinymceEditorOptions,
  onChange: any,
  placeholder?: string,
  qlContainerClassName?: string,
  defaulFontSize?: string,
  defaultFonSizeClass?: string,
  displayHtmlTab?: boolean,
  onTabChange?: any,
  showCharacterCount?: boolean,
  maxCharacterCount?: number,
}

const DEFAULT_EDITOR_TAB: number = 0
const WYSIWYG_EDITOR_TAB: number = 1
const TINY_MCE_DEFAULT_HEIGHT = '344'

export class WysiwygEditor extends Component<WysiwygEditorProps, WysiwygEditorState> {
  private quilEditor
  constructor(props: WysiwygEditorProps) {
    super(props)
    this.state = {
      currentColor: '',
      currentTab: this.isHtmlEdited() ? WYSIWYG_EDITOR_TAB : DEFAULT_EDITOR_TAB,
      html: props.html,
      htmlChanged: false,
      lastWysiwygHtml: this.isHtmlEdited() ? '' : props.html,
      placeholder: props.placeholder || 'Enter your text here',
    }
    this.colorChange = this.colorChange.bind(this)

  }

  public render() {
    return (
      <div className="r-wysiwyg">
        <Tabs selectedIndex={this.props.displayHtmlTab ? 1 : this.state.currentTab}
              onSelect={(index: number) => (this.selectTab(index))}>
          <TabList>
            <Tab id="quill-text-tab">Text</Tab>
            <Tab >HTML</Tab>
          </TabList>
          <TabPanel>
            <TinymceEditorComponent html={this.props.html}
              showCharacterCount={this.props.showCharacterCount}
              maxCharacterCount={this.props.maxCharacterCount}
              height={this.props.height || TINY_MCE_DEFAULT_HEIGHT}
              tinymceConfig={this.props.tinymceConfig}
              onChange={(content) => {this.handleWysiwygChange(content)}}></TinymceEditorComponent>
          </TabPanel>
          <TabPanel >
            <textarea className="wysiwyg-preview-html"
              value={this.props.html}
              onChange={(event: any) => this.handleRawEditorChange(event.target.value)}/>
          </TabPanel>
        </Tabs>
      </div>
    )
  }

  private colorChange(color) {
    if (this.quilEditor && this.quilEditor.format) {
      this.quilEditor.format('color', color)
    }
    this.setState({
      currentColor: color,
    })
  }

  private selectTab = (index: number) => {
    if ( this.props.onTabChange) {
      this.props.onTabChange(index)
    }
    if ( index === DEFAULT_EDITOR_TAB ) {
      this.returnToText()
    } else if ( index === WYSIWYG_EDITOR_TAB ) {
      this.startHtmlEditing()
    }
  }

  private returnToText = () => {
    if (this.state.htmlChanged) {
      const msg: string = !isEmpty(this.state.lastWysiwygHtml) ?
        Constants.HTML_SWITCH_PROMPT : Constants.HTML_SWITCH_CLEAR_PROMPT
      if (window.confirm(msg)) {
        this.setState({
          html: this.state.lastWysiwygHtml,
          htmlChanged: false,
          currentTab: DEFAULT_EDITOR_TAB,
        })
        this.props.onChange(this.state.lastWysiwygHtml, false)
      }
    } else {
      this.setState({
        currentTab: DEFAULT_EDITOR_TAB,
      })
    }
  }

  private handleWysiwygChange = (html: string) => {
    this.setState({
      html,
      lastWysiwygHtml: html,
    })
    this.props.onChange(html, false)
  }

  private handleRawEditorChange = (html: string) => {
    this.setState({
      html,
      htmlChanged: true,
    })
    this.props.onChange(html, true)
  }

  private isHtmlEdited = (): boolean => {
    // Input - if this represents HTML edited props, or if we've edited
    return Utils.isTruthy(this.props.htmlEdited) || (this.state && this.state.htmlChanged)
  }

  private startHtmlEditing = (): void => {
    this.setState({
      currentTab: WYSIWYG_EDITOR_TAB,
    })
  }
}
