export enum ValidationStatus {
  valid = 'valid',
  warning = 'warning',
  error = 'error',
  missing = 'missing',
  format = 'format',
}

export interface Validation {
  valid: boolean,
  message?: string,
  status: ValidationStatus,
}

export type Validator = (value: any) => Validation

const FORGIVING_DOMAIN_REGEX = /[\w.-]+(?:\.[\w\.-]+)/
const FORGIVING_URL_REGEX = /^((http|https):\/\/)[\w.-]+(?:\.[\w\.-]+)/
const STRICT_URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
const STRICT_EMAIL_ADDRESS_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const IMAGE_URL_INDEX = /^(http(s?):)([\/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/
const STRICT_MOBILE_REGEX = /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/

const validValidation: Validation = {
  valid: true,
  status: ValidationStatus.valid,
}

const missingValidation: Validation = {
  valid: false,
  status: ValidationStatus.missing,
}

export const validateDomain: Validator = (input: string) => {
  return ( !input || input === '') ?
      missingValidation
    : (!input.match(FORGIVING_DOMAIN_REGEX)) ?
      { valid: false, message: 'Invalid Domain format', status: ValidationStatus.format }
    : validValidation
}

export const validateForgivingUrl: Validator = (url: string) => {
  return (!url || url === '') ?
      missingValidation
    : (!url.match(FORGIVING_URL_REGEX)) ?
      { valid: false, message: 'Invalid URL format', status: ValidationStatus.format }
    : validValidation
}

export const validateImageUrl: Validator = (url: string) => {
  return (!url || url === '') ?
      missingValidation
    : (!url.match(IMAGE_URL_INDEX)) ?
      { valid: false, message: 'Invalid URL format', status: ValidationStatus.format }
    : validValidation
}

export const validateOptionalForgivingUrl: Validator = (url) => {
  return (
    url &&
    url !== '' &&
    !url.match(FORGIVING_URL_REGEX)
  )
    ? { valid: true, message: 'Invalid URL format', status: ValidationStatus.format }
    : validValidation
}

export const validateUrl: Validator = (url) => {
  return (!url || url === '') ?
      missingValidation
    : (!url.match(STRICT_URL_REGEX)) ?
      { valid: false, message: 'Invalid URL format', status: ValidationStatus.format }
    : validValidation
}

export const validateOptionalUrl: Validator = (url) => {
  return (
    url &&
    url !== '' &&
    !url.match(STRICT_URL_REGEX)
  )
    ? { valid: false, message: 'Invalid URL format', status: ValidationStatus.format }
    : validValidation
}

export const validateEmailAddress: Validator = (url) => {
  return (!url || url === '') ?
      missingValidation
    : (!url.match(STRICT_EMAIL_ADDRESS_REGEX)) ?
      { valid: false, message: 'Invalid Email Address format', status: ValidationStatus.format }
    : validValidation
}

export const validateOptionalUrlOrEmailAddress = (url: string): any => {
  return (
    url &&
    url !== '' &&
    !url.match(STRICT_EMAIL_ADDRESS_REGEX)
    && !url.match(STRICT_URL_REGEX)
  )
    ? { valid: false, message: 'Invalid URL or Email format', status: ValidationStatus.format }
    : validValidation
}

export const validateMobile = (mobile: string): any => {
  return (
    mobile &&
    mobile !== '' &&
    !mobile.match(STRICT_MOBILE_REGEX)
    && !mobile.match(STRICT_MOBILE_REGEX)
  )
    ? {valid: false, message: 'Invalid Mobile format', status: ValidationStatus.format }
    : validValidation
}

export const noValidationNeeded = () => validValidation
