import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { ImageUpload } from 'components/image-upload/component'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'
import { getConfiguration } from './utils'

interface HeaderSettingsProps {
  disableHeaderTextChange?: boolean,
  onChange: any,
  data: any,
  imageUrlAttribute: any,
  showHeaderAttribute: any,
  headerTextAttribute: any,
}

interface HeaderSettingsState {
  error: boolean,
  loadingImage: boolean
}
export class HeaderSettings extends React.Component<HeaderSettingsProps, HeaderSettingsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: HeaderSettingsProps) {
    super(props)
    this.state = {
      error: false,
      loadingImage: false,
    }
    this.uploadImage = this.uploadImage.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.communityId = activeCommunity.communityId
    })
  }

  public render() {
    const isShowHeader: boolean =
      isTruthy(getConfiguration(this.props.data, this.props.showHeaderAttribute))
    return <div className="logo-size">
      <span className="title">Header</span>
      <div className="section">
        <div className="block">
          <div className="toggle-active"
               onClick={() => {
                 this.props.onChange(this.props.showHeaderAttribute, !isShowHeader)
                }}>
            <button className="toggle-button"
                    style={{background: 'none', border: 'none', outline: 'none'}}>
              {isShowHeader ? <ToggleOn/> : <ToggleOff /> }
            </button>
            <span className="small-description">Show/Hide</span>
          </div>
        </div>
      </div>
      <div className="section">
        <ImageUpload imageUrl={getConfiguration(this.props.data, this.props.imageUrlAttribute)}
          showCloseButton={false} closeButtonClick={() => this.props.onChange(this.props.imageUrlAttribute, '')}
          uploadImage={this.uploadImage} />
      </div>
      <br />
      {this.props.disableHeaderTextChange &&
      <div className="section">
        <div className="small-title">
          Header Text
        </div>
        <WysiwygEditor
          htmlEdited={false}
          html={this.getHeaderText()}
          onChange={(html: string, isHtmlEdited: boolean) => {
            this.props.onChange(this.props.headerTextAttribute, html)
          }}/>
      </div>}
    </div>
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.setState({
          loadingImage: false,
        })
        this.props.onChange(this.props.imageUrlAttribute, hostedImage.url)
      })
      .catch((error) => {
        this.setState({
          error: true,
          loadingImage: false,
        })
      })
  }

  private getHeaderText = (): any => {
    const headerText = getConfiguration(this.props.data, this.props.headerTextAttribute)
    return headerText ? headerText : ''
  }
}
