import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Upgrade } from 'components/icons/upgrade'
import * as GA from 'google-analytics'
import {pipe, tap} from 'lodash/fp'
import * as React from 'react'
import {connect} from 'react-redux'
import * as Router from '../../router'
import { UPGRADE_BUTTON_COLOR } from './constants'

export const preventDefaultThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.preventDefault()),
  fn,
)

export const stopPropagationThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.stopPropagation()),
  fn,
)

export const preventDefault = () => (e: React.SyntheticEvent) => e.preventDefault()

interface ComponentProps {
  color?: UPGRADE_BUTTON_COLOR,
  showIcon?: boolean
  source: string,
  text: string,
}

interface UpgradeButtonOwnProps {
  dispatch?: any,
  callback?: any,
  style?: any,
}

type UpgradeButtonProps = ComponentProps & UpgradeButtonOwnProps

export const UpgradeButton = connect(
  (ownProps: UpgradeButtonOwnProps) => (ownProps),
  (dispatch) => ({dispatch}),
)(({source, text, ...props}: UpgradeButtonProps) =>
  <button
    className={`upgrade-button${props.color === UPGRADE_BUTTON_COLOR.WHITE ? '-white' : ''} primary btn btn-secondary`}
    onClick={preventDefaultThen(() => {
      if (source) {
        props.dispatch(GA.sendGa4Event(GA.createUpgradeEvent(source)))
        props.dispatch(Router.push(DashboardMenuOption.billing + '?upgrade_from=' + source))
      }
      if (props.callback) {
        props.callback()
      }},
    )}
    style={props.style}>
    {props.showIcon &&
      <div className="button-icon">
        <Upgrade svgwidth={30} svgheight={30}/>
      </div>}
    <div className="button-text">
      {text}
    </div>
  </button>)
