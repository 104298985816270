import { Loading } from 'components/loading';
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { BlogPost } from './types';

interface BlogPostProps {
  url: string;
}

interface BlogPostState {
  feed: BlogPost[];
  url: string;
  error?: string;
  isComponentLoading: boolean;
}

export class BlogPostWidgetComponent extends Component<
  BlogPostProps,
  BlogPostState
> {
  constructor(p: BlogPostProps) {
    super(p, '');
    this.state = {
      feed: [],
      url: '',
      isComponentLoading: true,
    };
  }

  public componentDidMount = () => {
    Promise.all([this.getBlogPosts()])
      .then(([blogsResponse]) => {
        if (blogsResponse) {
          this.setState({
            feed: blogsResponse,
            isComponentLoading: false,
          })
        } else {
          this.setState({
            isComponentLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isComponentLoading: false,
        });
      });
  };

  public render() {
    return (
      <div className="article-desc">
        {this.state.isComponentLoading ? (
          <div>
            <Loading size="64" />
          </div>
        ) : (
          <div>
            {this.state.feed.length > 0 ? (
              <a href={this.state.feed[0].url} target="_blank">
                <Row>
                  <Col sm={3}>
                    <div>
                      <label htmlFor="image" className="clickable-item">
                        {this.state.feed[0].image ? (
                          <img
                            src={this.state.feed[0].image}
                            style={{ maxWidth: 100, maxHeight: 100 }}
                          />
                        ) : (
                          <div className="no-image">No image</div>
                        )}
                      </label>
                    </div>
                  </Col>
                  <Col lg={9}>
                    <b>{this.state.feed[0].title}</b>
                    <br />
                    <span className="article-description">
                      {this.articleDescription()}
                    </span>
                  </Col>
                </Row>
              </a>
            ) : (
              <div>No feeds available.</div>
            )}
          </div>
        )}
      </div>
    );
  }

  private getBlogPosts() {
    return AjaxWrapper.ajax(
      AjaxWrapper.getServerUrl() + '/feed/fetch',
      HttpMethod.POST,
      {
        feedUrl: this.props.url,
      },
    )
      .then((feed) => {
          if (feed && feed.length > 0 ) {
            return feed
          } else {
            return null
          }
        },
      )
      .catch((err) => {
        return null
      });
  }

  private articleDescription(): string {
    if (
      this.state.feed[0].description &&
      this.state.feed[0].description.length > 200
    ) {
      return `${this.state.feed[0].description.substr(0, 200)} ...`;
    } else {
      return this.state.feed[0].description;
    }
  }
}
