import * as React from 'react'

interface DashboardWidgetItemProps {
  className: string
  activeCommunity: any,
  person: any,
  title: string,

}

export class WidgetItem extends React.Component<DashboardWidgetItemProps, any> {
  constructor(props: any) {
    super(props)
  }

  public render() {
    return <div className={'widget-item ' + this.props.className}>
        {this.props.title && <div className="widget-title">{this.props.title}</div>}
        <div className="widget-item-child">
          {this.props.children}
        </div>
      </div>
  }
}
