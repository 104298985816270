import * as React from 'react'
import {Button, Card, CardBody, Col, Input, Row} from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'
import * as Types from '../types'
import {TermsComponent} from './termsComponent'

export const SignupComponent = ({topArticles, data}: Types.WrappedState) => {
  const signupClassName = 'article-signup-card d-none d-lg-block';
  const signupText = Utils.getConfiguration(data,
    Constants.TopArticlesLayoutAttributes.SignupText)
    .replace('__COMMUNITY_NAME__', topArticles.communityName)

  return <Card className={signupClassName}>
    <CardBody>
      <Row>
        <Col md="11">
          <div className="article-signup-text" dangerouslySetInnerHTML={
            {__html: signupText }}>
          </div>
        </Col>
        <Col md="1"></Col>
      </Row>
      {/* <Row>
        <Col>{Constants.SIGNUP_SECOND_LINE_TEXT(topArticles.communityName)}</Col>
      </Row> */}
      <div className="d-article-signup-email-container">
        <Input
          type="email"
          placeholder="Email Address (required)" />
        <Button className="rasa-submit-btn d-article-signup-btn"
          style={{ backgroundColor: Utils.getConfiguration(data,
          Constants.TopArticlesLayoutAttributes.SignupButtonColor)}}>
          {Utils.getConfiguration(data,
            Constants.TopArticlesLayoutAttributes.SignupButtonText)}
        </Button>
      </div>
      {Utils.getConfiguration(data, Constants.TopArticlesLayoutAttributes.TermsUrl) &&
        <TermsComponent topArticles={topArticles} />}
    </CardBody>
  </Card>
}
