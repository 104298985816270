import { sizedUrl } from 'app/utils'
import * as React from 'react'
import {Col, Row} from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'
import * as Types from '../types'
import {ReadMoreComponent} from './readMoreComponent'

export const RegularArticleCard = ({article, configurations}: Types.ArticleProps) => {
  return <Col md="6" >
    <div className="d-regular-article-rectangle">
      <Row className="d-regular-article-top-part">
        <Col md="7">
          <Row>
            <Col className="d-regular-article-title">
              <a id="track-url" href={article.url + '?utm_source=rasaio&utm_medium=top-articles&link=title'}
                 target="_blank" rel="noopener noreferrer">
                {Utils.getShortText(article.title, Constants.SHORT_TITLE_LENGTH)}
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-article-source">{article.site_name}</Col>
          </Row>
        </Col>
        <Col  md="5" className="d-regular-article-image image">
        <a id="track-url" href={article.url + '?utm_source=rasaio&utm_medium=top-articles&link=image'}
           target="_blank" rel="noopener noreferrer">
          <img src={sizedUrl(article.hosted_image_url,
                                               Constants.DESKTOP_REGULAR_IMAGE_SIZE,
                                               Constants.DESKTOP_REGULAR_IMAGE_SIZE)} /> </a>
        </Col>
      </Row>
      <Row>
        <Col className="d-regular-article-description">
          {Utils.getShortText(article.description, Constants.SHORT_DESCRIPTION_LENGTH)}
        </Col>
      </Row>
      <ReadMoreComponent article={article}
        configurations={configurations}
        rowClassName={'d-regular-article-read-more-row'}
        colClassName={'d-regular-article-read-more'} />
    </div>
  </Col>
}
