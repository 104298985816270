import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import React, {Component} from 'react'
import { DEFAULT_TIMEZONE } from '../../shared_server_client/constants'
import './styles.css'
import { loadTimeZones } from '../../shared_server_client/utils'

interface TimeZoneState {
  timeZone: string,
  timezones?: DropdownOption[],
}

interface TimeZoneProps extends TimeZoneState {
  onChange: any,
}

export class RasaTimeZone extends Component<TimeZoneProps, TimeZoneState> {
  constructor(props: TimeZoneProps) {
    super(props)

    this.state = {
      timeZone: props.timeZone || DEFAULT_TIMEZONE,
      timezones: [],
    }
  }

  public componentDidMount() {
    Promise.all([
      loadTimeZones(),
    ]).then(([timezones]) => {
      const mappedTimezones = timezones
        .map((element: {timezone: string}) => ({ key: element.timezone, value: element.timezone }))
      this.setState({timezones: mappedTimezones})
    })
  }

  public render() {
    return <div className="time-zone-wrapper">
      <DropdownComponent
          data={this.state.timezones}
          selected={this.state.timeZone}
          onChange={this.timeZoneChanged}/>
    </div>
  }

  private timeZoneChanged = (e: OnChangeEvent) => {
    this.setState({timeZone: e.selected.key.toString()})
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }
}
