import { RasaContext } from 'context'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import {TAB_SELECTED} from 'store/constants'
import './styles.css'

export interface MenuItem {
  component?: any,
  key: string,
  name: string,
  preLoad?: boolean
}
export interface TabMenuMessage {
  code: string,
  payload: any,
  sourceElement: any,
}

export interface TabMenuProps {
  menus: MenuItem[],
  noDispatch?: boolean,
  onSelect?: any,
  selectedItemKey: string,
  selectedItemProps?: any,
  onSearch?: any,
}

export interface MenuComponent {
  navigate?(menuItemKey: string): void
  publishMessage?(message: TabMenuMessage): void
  registerChild?(childElement: any): void
}

export interface MenuComponentProps {
  menuComponent: MenuComponent
}

export class TabMenu extends React.Component<TabMenuProps, any> implements MenuComponent {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(p: TabMenuProps) {
    super(p)
    this.state = {selectedItemKey: this.props.selectedItemKey}
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.hash),
    ])
    .then(([hash]) => {
      if (hash) {
        this.navigate(hash)
      }
    })
  }

  public registerChild(childElement: any) {
    this._components[this._components.length] = childElement
  }

  public navigate(menuItemKey: string) {
    const m = this.props.menus.find((item) => item.key === menuItemKey)
    if (m) {
      this.handleSelect(m)
    }
  }

  public publishMessage(message: TabMenuMessage) {
    for (const c of this._components) {
      if (c !== message.sourceElement && c.receiveMessage) {
        c.receiveMessage(message)
      }
    }
  }

  public render() {
    return <div>
      {/* First, output the menus themselves at the top */}
      <div className="option-wrapper">
        {this.props.menus.map((menu: MenuItem, i: number) => {
            return  <div  key={i}
                          onClick={() => this.handleSelect(menu)}
                          className={`clickable-item select-option ${this.state.selectedItemKey === menu.key ?
                                                                  'select-option-selected' : ''}`}>
                      {menu.name}
                    </div>
        })}
      </div>

      {/* Next, output the copmonents that correspond to each of the menu items  */}
      <div className="clearfix">
        {this.props.menus.map((menu: MenuItem, i: number) => {
            return (menu.component && (this.state.selectedItemKey === menu.key || menu.preLoad))
              ? (<div key={i} className={this.state.selectedItemKey === menu.key ? '' : 'hide-element'}>
                  {React.createElement(menu.component, {...this.props.selectedItemProps,
                    onSearch: this.props.onSearch, menuComponent: this})}
                </div>)
              : null
        })}
      </div>
    </div>
  }
  private _components: any[] = []

  protected handleSelect(menu: MenuItem) {
    this.setState({selectedItemKey: menu.key})
    if (!this.props.noDispatch) {
      this.context.store.dispatch({ type: TAB_SELECTED, hash: menu.key })
    }
    if (this.props.onSelect) {
      this.props.onSelect(menu) // fire the event if handler provided
    }
  }
}
