import { RasaContext } from 'context'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { WIDGET_TAB_SELECTED } from './constants'
import './styles.css'

import './signup/style.scss'
import { MenuSection } from './types'

interface EditNavigationProps {
  section: MenuSection,
  onClick: any,
  selected: boolean,
  isLastElement: boolean,
  icon: any,
}

const EditNavigation = ({section, onClick, selected, isLastElement}: EditNavigationProps) => {
  return (
  <div className={`design-menu clickable-item menu-item ${selected ? 'selected' : ''} ${isLastElement ? 'extra-margin' : ''}`}
      onClick={() => onClick(section)}>
    {section.icon}
    {section.value}
  </div>)}
interface PreviewWidgetMenuComponentProps {
  isDirty: boolean,
  saveRecord: any,
  selectItem: any,
  menuSections: MenuSection[]
}
interface PreviewWidgetMenuComponentState {
  selected?: MenuSection,
}

export class PreviewWidgetMenuComponent extends React.Component<PreviewWidgetMenuComponentProps,
      PreviewWidgetMenuComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props) {
    super(props)
    this.state = {
      selected: props.menuSections[0],
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.hash),
    ])
    .then(([hash]) => {
      const selectedItem = (hash && this.isValidTab(hash)) ? this.getMenuSection(hash) : this.props.menuSections[0]
      this.setState({
        selected: selectedItem,
      })
      this.selectItem(selectedItem)
    })
  }

  public render() {
    return (
      <div>
          {this.props.menuSections.map((section, i: number) =>
            <EditNavigation key={section.key}
            isLastElement={i === this.props.menuSections.length - 1}
            section={section}
            icon={section.icon || ''}
            selected={section.key === this.state.selected.key}
            onClick={this.selectItem}/>)}
      </div>)
  }

  private isValidTab = (tab: string): boolean => {
    return this.props.menuSections.filter((validTab) => validTab.key === tab.toLowerCase()).length > 0
  }

  private getMenuSection = (hash: string) => {
    const match = this.props.menuSections.filter((section) => section.key.toLowerCase() === hash.toLowerCase())[0]
    return match ? match : this.props.menuSections.length > 0 ? this.props.menuSections[0] : null
  }

  private selectItem = (item: MenuSection) => {
    this.setState({
      ...this.state,
      selected: item,
    })
    this.props.selectItem(item)
    this.context.store.dispatch({type: WIDGET_TAB_SELECTED, hash: item.key})
  }

}
