import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import * as Utils from '../../utils'
import * as Constants from '../constants'

export const Sidebar = ({data}) => {
  const signupBannerPlacement = Utils.getConfiguration(data, Constants.SignupLayoutAttributes.BannerPlacement)
      .toLowerCase().trim();
  const sidebarClassName = 'signup-sidebar-' + signupBannerPlacement;
  const url = Utils.getConfiguration(data, Constants.SignupLayoutAttributes.SidebarImageUrl)
  const text = Utils.getConfiguration(data, Constants.SignupLayoutAttributes.SignupLeadText)
  return url || text ? <ReactStrap.Col md="12" lg="4" className={sidebarClassName}>
    <ReactStrap.Row>
      <ReactStrap.Col md="6" lg="12">
        <div className="sidebar-text">
          <span dangerouslySetInnerHTML={{
            __html: text,
          }}>
          </span>
        </div>
      </ReactStrap.Col>
      <ReactStrap.Col md="6" lg="12">
        {url &&
          <div className="sidebar-image">
            <img src={url}/>
          </div>
        }
      </ReactStrap.Col>
    </ReactStrap.Row>
  </ReactStrap.Col> : <div></div>
}
