import { WysiwygEditor } from 'components/wysiwyg/component'
import { RasaContext } from 'context'
import { stripHTML } from 'generic/utility'
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { getConfiguration } from '../../utils'
import { EditSectionProps } from '../types'
import { PrivacyLayoutAttributes } from '../constants'

interface PrivacySettingsState {
    communityId: string,
    communityIdentifier: string,
    enableText: boolean,
    error: boolean,
    loadingImage: boolean,
}

export class PrivacySettings extends React.Component<EditSectionProps, PrivacySettingsState> {
    public static contextType = RasaContext
    private sharedStore: SharedStore
    constructor(props: EditSectionProps) {
        super(props)
        this.state = {
            communityId: '',
            communityIdentifier: '',
            enableText: false,
            error: false,
            loadingImage: false,
        }
    }

    public componentDidMount = () => {
        this.sharedStore = SharedStore.instance(this.context)
        this.sharedStore.getValue(SharedKeys.activeCommunity)
            .then((activeCommunity) => {
                this.setState({
                    communityId: activeCommunity.data.community_id,
                    communityIdentifier: activeCommunity.communityId,
                })
            })
    }

    public render() {
        return <div className="logo-size">
            <span className="title">{this.props.name}</span>
            <div className="section">
                <div className="block">
                    <div>
                        <span>Text</span>
                        <WysiwygEditor
                            htmlEdited={false}
                            html={this.getSidebarText()}
                            onChange={(html: string, isHtmlEdited: boolean) => {
                                if (stripHTML(html) === '') {
                                    this.props.onChange(PrivacyLayoutAttributes.PrivacyLeadText, '')
                                } else {
                                    this.props.onChange(PrivacyLayoutAttributes.PrivacyLeadText, html)
                                }
                            }}/>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    }

    private getSidebarText = (): any => {
        const sidebarText = getConfiguration(this.props.data, PrivacyLayoutAttributes.PrivacyLeadText)
        return sidebarText ? sidebarText : ''
    }
}
