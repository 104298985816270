import * as React from 'react'

interface WidgetIframeProps {
  url: string,
  width?: string,
  height?: string,
}
export class WidgetIframe extends React.Component<WidgetIframeProps, any> {
  constructor(props: any) {
    super(props)
  }

  public render() {
    return <iframe width={this.props.width ? this.props.width : null}
              height={this.props.height ? this.props.height : null}
              className="widget-iframe"
              src={this.props.url}
            />
  }
}
