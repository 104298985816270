import { SelectColor } from 'components/colors/component'
import * as Dropdown from 'components/dropdown/component'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { ImageUpload } from 'components/image-upload/component'
import { ENABLE_RECAPTCHA_KEY } from 'components/widgets/constants'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { isTruthy, stripHTML } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'
import { getConfiguration } from '../../utils'
import { bannerPlacementDropdownOptions, SignupLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'

interface SignupSettingsState {
  communityId: string,
  communityIdentifier: string,
  enableRecaptcha: boolean,
  error: boolean,
  loadingImage: boolean,
}

export class SignupSettings extends React.Component<EditSectionProps, SignupSettingsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: EditSectionProps) {
    super(props)
    this.state = {
      communityId: '',
      communityIdentifier: '',
      enableRecaptcha: false,
      error: false,
      loadingImage: false,
    }
    this.uploadImage = this.uploadImage.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        this.setState({
          communityId: activeCommunity.data.community_id,
          communityIdentifier: activeCommunity.communityId,
        }, () => {
          this.loadData(false)
        })
      })
  }

  public render() {
    return <div className="logo-size">
      <span className="title">{this.props.name}</span>
      <div className="section">
        <div className="block">
          <div className="recaptcha-wrapper">
            <div className="enable-recaptcha" onClick={this.toggleEnableRecaptcha}>
              <span className="toggle-label">Enable Recaptcha</span>
              <button className="toggle-button"
                      style={{background: 'none', border: 'none', outline: 'none'}}>
                {this.state.enableRecaptcha ? <ToggleOn/> : <ToggleOff/> }
              </button>

            </div>
            <div className="recaptcha-note">
              If this is enabled, then the direct API signup call will not work.
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="block">
        <div className="title">Sidebar</div>
          <div>
            <span>Placement</span>
            <Dropdown.DropdownComponent
              data={bannerPlacementDropdownOptions}
              selected={
                getConfiguration(
                  this.props.data,
                  SignupLayoutAttributes.BannerPlacement)}
              onChange={(e) => {
                this.props.onChange(
                  SignupLayoutAttributes.BannerPlacement,
                  e.selected.key,
                )}}/>
          </div>
          <div>
            <span>Text</span>
            <WysiwygEditor
              htmlEdited={false}
              html={this.getSidebarText()}
              onChange={(html: string, isHtmlEdited: boolean) => {
                if (stripHTML(html) === '') {
                  this.props.onChange(SignupLayoutAttributes.SignupLeadText, '')
                } else {
                  this.props.onChange(SignupLayoutAttributes.SignupLeadText, html)
                }
              }}/>
          </div>
        </div>
      </div>
      <div className="section">
        <ImageUpload imageUrl={getConfiguration(this.props.data, SignupLayoutAttributes.SidebarImageUrl)}
          showCloseButton={true} closeButtonClick={() => this.props.onChange(
            SignupLayoutAttributes.SidebarImageUrl, '')}
          uploadImage={this.uploadImage} />
      </div>
      <div className="section">
      <div className="block">
          <div className="title">Signup Button</div>
          <div>
            <span>Text</span>
            <Input value={
              getConfiguration(this.props.data, SignupLayoutAttributes.SignupButtonText)}
              maxLength={100}
              onChange={(e) => {
                this.props.onChange(SignupLayoutAttributes.SignupButtonText, e.target.value)
              }}/>
          </div>
          <div>
            <span>Color</span>
            <SelectColor
              key={SignupLayoutAttributes.SignupButtonColor}
              className={'secondary'}
              selectColor={(c: string) => {
                this.props.onChange(SignupLayoutAttributes.SignupButtonColor, c)}}
              color={getConfiguration(this.props.data,
                SignupLayoutAttributes.SignupButtonColor, '#000000')} />
          </div>
        </div>
      </div>
    </div>
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.communityIdentifier}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.setState({
          loadingImage: false,
        })
        this.props.onChange(SignupLayoutAttributes.SidebarImageUrl, hostedImage.url)
      })
      .catch((error) => {
        this.setState({
          error: true,
          loadingImage: false,
        })
      })
  }

  private getSidebarText = (): any => {
    const sidebarText = getConfiguration(this.props.data, SignupLayoutAttributes.SignupLeadText)
    return sidebarText ? sidebarText : ''
  }

  private toggleEnableRecaptcha = () => {
    const newData = !this.state.enableRecaptcha
    this.setState({
      enableRecaptcha: newData,
    })
    return this.context.entityMetadata.getEntityObject('community_configuration')
    .then((entity) => {
      entity.setRecordId(this.state.communityIdentifier, null)
      entity.data.community_id = this.state.communityId
      entity.data.key = ENABLE_RECAPTCHA_KEY
      entity.data.value = newData
      entity.data.is_active = true
      return entity.save().then(() => {
        this.loadData(true)
      })
    })
  }

  private loadData = (forceRefresh: boolean) => {
    const params = [
      {param: 'key', value: ENABLE_RECAPTCHA_KEY},
      {param: 'forceRefresh', value: forceRefresh},
    ]
    return new Dataset().loadCommunityDataset('communityConfiguration', this.state.communityIdentifier, params)
      .then((configuration) => {
        if (configuration.length > 0) {
          this.setState({
            enableRecaptcha: configuration[0][0].value ? isTruthy(configuration[0][0].value) : false,
          })
        }
      })
  }
}
