import { OpenLinkIcon } from 'components/icons/openlink'
import { Sources } from 'components/icons/sources'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import * as GenericRedux from '../../generic/genericRedux'
import { copyToClipboard, getEnvironmentSuffix } from '../../generic/utility'
import './styles.css'

interface RssLinkComponentProps {
  name: string,
  description: string,
  link: string,
}

const RssLinkComponent = (props: RssLinkComponentProps) =>
  <div className="rss-link">
    <div className="left-part">
      <div className="first-line">
        <div className="name">{props.name}</div>
      </div>
      <div className="description">{props.description}</div>
    </div>
    <div className="middle-part">
      <a target="_blank" href={props.link} className="open-link"><OpenLinkIcon /></a>
    </div>
    <div className="right-part">
      <input type="text" value={props.link} readOnly={true}/>
      <button onClick={() => copyToClipboard(props.link) }>Copy</button>
    </div>
  </div>

interface WidgetsRssState {
  communityId: string
  communityGuid: string
}

class WidgetsRssComponent extends Component<any, WidgetsRssState> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)

    this.state = {
      communityId: '',
      communityGuid: '',
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        communityGuid: activeCommunity.data.community_guid,
      })
    })
  }

  public render() {
    return (
      <div className="widgets-tools-rss">
        <div className="contentpane-header-wrapper">
          <div className="contentpane-icon-wrapper">
            <Sources/>
          </div>
          <div className="header">
            <div className="contentpane-section-title-wrapper">
              <div className="section-header-text">RSS feed</div>
            </div>
          </div>
        </div>
        <RssLinkComponent
          name="RSS feed"
          description="This is your personalized RSS feed that will dynamically update with the most popular articles from your newsletter."
          link={this.rssUrl()}/>

     </div>
    )
  }

  private rssUrl = () => {
    return `https://public-api${getEnvironmentSuffix()}.rasa.io/top-articles/${this.state.communityGuid}?format=rss`
  }
}

export const WidgetsRss = GenericRedux.registerNewDatasetComponent(
  WidgetsRssComponent, 'widgets_rss')
