import bodybuilder from 'bodybuilder'
import { Topics } from 'components/icons/topics'
import { WordCloud } from 'components/word-cloud/component'
import { DateRanges, DateRangesAsDropdownOptions } from 'elasticsearch/constants'
import { AggregationType, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import React, { ComponentType } from 'react'
import { connect, ConnectedComponentClass } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Router from 'router'
import { RasaAnalyticsComponent } from '../../analytics/rasa-analytics-component'
import {TopTopic} from './types'
import {Fields} from "../../../shared/modals";

export class TopTopicsComponentClass extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selectedDateRange: DateRangesAsDropdownOptions[5],
    }
  }

  public render() {
    return (
      <div>
        <div className="widget-title"><a href="#" onClick={() => this.props.push('/analytics/topics')}>
          {this.props.title}</a>
        </div>
        <TopTopicsWidget dateRange={DateRanges.WeeklyAverage}/>
      </div>
    )
  }
}

export const TopTopicsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(TopTopicsComponentClass)

type Topics = TopTopic[]

interface TopicsProps extends ElasticsearchProps<Topics> {
  dateRange: any,
  shuffle: true,
}

interface TopicsState {
  loaded: boolean,
}

const TAG_NAME_AGG: string = 'tag'

class TopTopicsWidgetComponent extends ElasticsearchComponent<Topics, TopicsProps, TopicsState> {
  constructor(p: TopicsProps) {
    super(p, IndexName.TAGS)
    this.state = {
      loaded: false,
    }
  }

  public parseResponse(payload: ResponsePayload): Promise<Topics> {
    this.setState({
      loaded: true,
    })
    const aggregations = payload.aggregations[TAG_NAME_AGG]
    return Promise.resolve(aggregations.buckets.map((aggregation: ResponseAggregate) => ({
      name: aggregation.key,
      totalClicks: aggregation.doc_count,
      count: aggregation.doc_count,
      value: aggregation.key,
      key: aggregation.key,
    })))
  }

  public componentDidUpdate(oldProps: TopicsProps) {
    if ( this.props.dateRange !== oldProps.dateRange ) {
      this.search()
    }
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
    .query(FilterType.range, 'message_send_date', toFilter(this.props.dateRange))
    return this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'text.keyword',
      name: TAG_NAME_AGG,
      extra: { size: '30' },
    }).build()
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.props.results && this.props.results.length > 0 ?
      <div>
        <div>
    <ReactStrap.Row>
    <ReactStrap.Col sm="12" md="12">
            <WordCloud data={this.props.results} shuffle={false} />
          </ReactStrap.Col>
    </ReactStrap.Row>
        </div>
      </div> :
      <div>
        <p className="no-data-tag-widget">
            No Topic Available
        </p>
    </div>
      }
    </div>
  </div>
}

export const TopTopicsWidget: ConnectedComponentClass<ComponentType<TopTopicsWidgetComponent>, Fields> = GenericRedux.registerNewComponent(
  TopTopicsWidgetComponent, 'home_topics', {})
