import { MenuArticleSettings } from 'components/icons/menu-article-settings'
import { HeaderLogo } from 'components/icons/menu-header-logo'
import { SettingsIcon} from 'components/icons/settings'
import React from 'react'
import * as Types from '../types'
import * as Utils from '../utils'
import { DropdownOption } from '../../dropdown/component'

export const SHORT_DESCRIPTION_LENGTH = 100
export const SHORT_TITLE_LENGTH = 30

export const DESKTOP_LEAD_IMAGE_SIZE = 300
export const DESKTOP_REGULAR_IMAGE_SIZE = 200

export const READ_MORE = 'READ MORE'

export const TOP_ARTICLES_TAB_SELECTED = 'TAB_SELECTED'

export enum TopArticlesLayoutAttributes {
  ShowHeader = 'top_articles_header_show',
  HeaderText = 'top_articles_header_text',
  HeaderImageUrl = 'top_articles_header_image_url',
  ArticlesMaxCount = 'top_articles_max_count',
  ReadMoreButtonText = 'top_articles_read_more_button_text',
  ReadMoreButtonColor = 'top_articles_read_more_button_color',
  ShowSignup = 'top_articles_signup_show',
  SignupText = 'top_articles_signup_text',
  SignupButtonText = 'top_articles_signup_button_text',
  SignupButtonColor = 'top_articles_signup_button_color',
  PrimaryColor = 'primary_color',
  TermsUrl = 'terms_and_conditions_url',
  BrandHeaderImageUrl = 'brand_header_image_url',
  TopArticlesDate =  'top_articles_date'
}

export const REDUX_STORE_HOME = 'top_articles_layout'

export enum MenuItems {
  headerAndLogo = 'Header Logo',
  cta = 'CTA',
  signup = 'Signup',
}

export const DELIMITER = '|'

export const DateRanges = {
  LastWeek: `now-1w${DELIMITER}now/d`,
  LastMonth: `now-1M/M${DELIMITER}now/M`,
  Last90Days: `now-90d${DELIMITER}now/d`,
}

export const DateRangesAsDropdownOptions: DropdownOption[] = [
  {key: '1', value: DateRanges.LastWeek, description: 'Last Week'},
  {key: '2', value: DateRanges.LastMonth, description: 'Last Month'},
  {key: '3', value: DateRanges.Last90Days, description: 'LAST 90 DAYS'},
]


export const MenuSections: Types.MenuSection[] = [
  {
    icon: <HeaderLogo />,
    key: Utils.getEnumHash(MenuItems.headerAndLogo, MenuItems),
    value: MenuItems.headerAndLogo,
  },
  {
    icon: <MenuArticleSettings />,
    key: Utils.getEnumHash(MenuItems.cta, MenuItems),
    value: MenuItems.cta,
  },
  {
    icon: <SettingsIcon svgwidth={27.5} svgheight={27.5} />,
    key: Utils.getEnumHash(MenuItems.signup, MenuItems),
    value: MenuItems.signup,
  },
]
